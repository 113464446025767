import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import translations from '../../../translations/en.json';
import {
  SST_PAGE_VIEW_MACHINE_CENTER,
} from '../../../Constants'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import PageHeader from '../../../shared/components/PageHeader/PageHeader'
import * as React from 'react'
import {
  deepEqual,
  handlePermissionRedirect,
  PERMISSION_METHOD_GET,
} from '../../../shared/Utilities'
import { SiteContext } from '../../../Context'
import { getEntityPermissions } from '../../../query/entities/auth'
import { PERMISSION_METHOD_INSERT } from '../../../shared/Utilities'
import { getControlPoints, updateControlPoints } from '../../../query/entities/controlPoints'
import useStyles from './Styles.js';
import { Typography } from '@mui/material'
import QuantifeelSvgIcon from '../../../shared/components/QuantifeelSvgIcon/QuantifeelSvgIcon'
import {ReactComponent as ArrowUp} from '../../../img/icons/arrow_up.svg'
import {ReactComponent as ArrowDown} from '../../../img/icons/arrow_down.svg'
import FullScreenCircularProgress from '../../../shared/components/FullScreenCircularProgress'
import PageFooter from '../../../shared/components/PageFooter/PageFooter'
import ProgressButton, { PROGRESS_BUTTON_VARIANTS } from '../../../shared/components/ProgressButton/ProgressButton'
import SaveConfirmationDialog from '../../../shared/components/Dialogs/SaveConfirmationDialog/SaveConfirmationDialog'
import { useSnackbar } from 'notistack'

const pageTitle = translations.common.controlPoints.reOrderControlPoints

const acceptablePagePermission = [
  {entity: 'Line', method: PERMISSION_METHOD_INSERT, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const DIRECTION = {
  UP: "up",
  DOWN: "down"
}

const ViewMachineCenter = (props) => {

  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const queryClient = useQueryClient();

  const { currentCustomer, hasPermission } = useContext(SiteContext);

  const match = useRouteMatch();
  const machineCenterId = match.params.machineCenterId;

  const [isLoading, setIsLoading] = useState(false);
  const [controlPoints, setControlPoints] = useState([]);
  const [isSaveConfirmationDialogOpen, setIsSaveConfirmationDialogOpen] = useState(false);

  // ------------------------------
  // -- BEGIN useQuery / useMemo --
  // ------------------------------

  const memoizedEmptyArrayDefaultValue = useMemo(() => [], []);

  const {isLoading: isLoadingCustomerEntityPermissions, data: customerEntityPermissions} = useQuery(
    ['entityPermissions', currentCustomer, 'machineCenter', {ids: [machineCenterId]}],
    getEntityPermissions,
    { enabled: !!machineCenterId }
  );

  const {isLoading: isLoadingInitialControlPoints, data: initialControlPoints= memoizedEmptyArrayDefaultValue} = useQuery(
    ['controlPoints', {machineCenterId: machineCenterId}],
    getControlPoints,
    { enabled: !!machineCenterId }
  );

  const {mutate: mutateUpdate, isLoading: isUpdating} = useMutation(updateControlPoints, {
    onSuccess: (data) => {
      enqueueSnackbar(translations.pages.reOrderControlPoints.controlPointsUpdated, {variant: 'success'});
      queryClient.removeQueries('controlPoints');
    },
    onError: ({response: {data}}) => {
      enqueueSnackbar(data.message, {variant: 'error'});
    },
    onSettled: () => {
      setIsSaveConfirmationDialogOpen(false)
    }
  });

  /**
   * Given changes to initialControlPoints,
   * Set controlPoints, for purposes of tracking isDirty by comparing and controlPoints with initialControlPoints...
   */
  useEffect(() => {
    setControlPoints(JSON.parse(JSON.stringify(initialControlPoints)))
  }, [initialControlPoints])

  /**
   * Given changes to machineCenterId, permissions
   * Set isReadOnly...
   */
  const isReadOnly = useMemo(() => {
    return (
      !customerEntityPermissions?.machinecenter[machineCenterId]?.update
    )
  }, [machineCenterId, customerEntityPermissions])

  /**
   * Given changes to controlPoints, initialControlPoints...
   * Return isDirty...
   */
  const isControlPointsDirty = useMemo(() => {
    // If not deepEqual, then dirty...
    return !deepEqual(controlPoints, initialControlPoints);
  }, [controlPoints, initialControlPoints]);

  // ----------------------------
  // -- END useQuery / useMemo --
  // ----------------------------

  // ----------------------
  // -- BEGIN useEffects --
  // ----------------------

  /**
   * On component mount...
   */
  useEffect(() => {

    // Validate permissions...
    handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission)

    // Set page title..
    document.title = pageTitle;

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Given changes to loading states,
   * Set isLoading...
   */
  useEffect(() => {
    if ( isLoadingCustomerEntityPermissions ||
         isLoadingInitialControlPoints ) {
      setIsLoading(true)
    }
    else {
      setIsLoading(false)
    }
  }, [isLoadingCustomerEntityPermissions, isLoadingInitialControlPoints])

  // --------------------
  // -- END useEffects --
  // --------------------

  // --------------------------
  // -- BEGIN event handlers --
  // --------------------------

  const onBack = () => {
    // If there is a page in history, go back, else, go to /view-machine-center/{machineCenterId}...
    return !!(history.location.key) ? history.goBack() : history.push(`/${SST_PAGE_VIEW_MACHINE_CENTER}/${machineCenterId}`);
  }

  const onSave = () => {
    mutateUpdate({
      machineCenterId,
      controlPoints
    });
  }

  /**
   * Given a controlPoint and direction, swap controlPointIndexes with the controlPoint in the given direction...
   * @param controlPoint
   * @param direction
   */
  const onMove = ({controlPoint, direction}) => {

    // Get indexes...
    const currentIndex = controlPoint.controlPointIndex;
    let targetIndex = direction === DIRECTION.UP ? currentIndex - 1 : currentIndex + 1;
    let targetControlPoint = controlPoints.find(cp => cp.controlPointIndex === targetIndex);

    // If targetControlPoint is inactive, go to the next one...
    while ( targetControlPoint && targetControlPoint.inactive ) {
      targetIndex = direction === DIRECTION.UP ? targetIndex - 1 : targetIndex + 1;
      targetControlPoint = controlPoints.find(cp => cp.controlPointIndex === targetIndex);
    }

    // If no targetControlPoint, we've reached an edge and will not swap indexes, so return...
    if (!targetControlPoint) {
      return;
    }

    // Swap indexes...
    controlPoint.controlPointIndex = targetControlPoint.controlPointIndex;
    targetControlPoint.controlPointIndex = currentIndex;

    // Ensure component thinks complex object controlPoints has changed, by deep copying...
    setControlPoints(JSON.parse(JSON.stringify(controlPoints)));
  }

  // ------------------------
  // -- END event handlers --
  // ------------------------

  // -------------------
  // -- BEGIN renders --
  // -------------------

  const classes = useStyles();

  const renderHeader = () => {
    return (
      <PageHeader
        onBack={onBack}
        pageTitle={pageTitle}
      />
    )
  }

  const renderControlPointsList = () => {

    const controlPointsShallowCopySortedByControlPointIndexAsc =
      controlPoints.slice() // Shallow copy, so as not mutate controlPoints with sort() and still retain controlPoints element references...
        .filter(controlPoint => !controlPoint.inactive) // Filter active controlPoints...
        .sort((a, b) => a.controlPointIndex - b.controlPointIndex); // Sort for purposes of displaying elements in .controlPointIndex order...

    return (
      <div className={classes.controlPointsListContainer}>
        {controlPointsShallowCopySortedByControlPointIndexAsc
          .map((controlPoint, index) => renderControlPointsContainerRow({
            controlPoint,
            displayIndex: (index + 1), // Start displayIndex at 1 (aka 1 indexed)...
            hideMoveUp:
              isReadOnly ||
              index === 0, // First element...
            hideMoveDown:
              isReadOnly ||
              (index === controlPointsShallowCopySortedByControlPointIndexAsc.length - 1) // Last element...
          }))
        }
      </div>
    )
  }

  const renderControlPointsContainerRow = ({controlPoint, displayIndex, hideMoveUp, hideMoveDown}) => {
    return (
      <div key={controlPoint.id} className={classes.controlPointsListRow}>

        {/* Display Index */}
        <Typography className={classes.controlPointIndexLabel}>
          {`${displayIndex}.`}
        </Typography>

        <div className={classes.controlPointsListRowInnerContainer}>

          {/* Name */}
          <Typography className={classes.controlPointNameLabel}>
            {controlPoint.name}
          </Typography>

          {/* Buttons */}
          <>
            {/* Move Up */}
            <QuantifeelSvgIcon
              className={classes.moveIcon}
              style={{ visibility: hideMoveUp ? 'hidden' : 'visible' }}
              component={ArrowUp}
              viewBox="0 0 24 24"
              onClick={() => onMove({controlPoint, direction: DIRECTION.UP})}
            />

            {/* Move Down */}
            <QuantifeelSvgIcon
              className={classes.moveIcon}
              style={{ visibility: hideMoveDown ? 'hidden' : 'visible' }}
              component={ArrowDown}
              viewBox="0 0 24 24"
              onClick={() => onMove({controlPoint, direction: DIRECTION.DOWN})}
            />
          </>

        </div>

      </div>
    )
  }

  const renderFooter = () => {
    return (
      <PageFooter>
        <div className={classes.footerContentContainer}>

          {/* Cancel */}
          <div className={classes.footerContentContainerSubContainer}>
            <ProgressButton
              variant={PROGRESS_BUTTON_VARIANTS.CANCEL}
              text={translations.common.cancel}
              onClick={onBack}
            />
          </div>

          {/* Save */}
          <div className={classes.footerContentContainerSubContainer}>
            <ProgressButton
              variant={PROGRESS_BUTTON_VARIANTS.ACTION}
              text={translations.common.save}
              disable={isReadOnly || !isControlPointsDirty}
              onClick={() => setIsSaveConfirmationDialogOpen(true)}
            />
          </div>

        </div>
      </PageFooter>
    )
  }

  const renderSaveConfirmationDialog = () => {
    return (
      <SaveConfirmationDialog
        open={isSaveConfirmationDialogOpen}
        isSaving={isUpdating}
        onClose={() => setIsSaveConfirmationDialogOpen(false)}
        onCancel={() => setIsSaveConfirmationDialogOpen(false)}
        onSave={onSave}
      />
    )
  }

  return (
    <>
      {isLoading && <FullScreenCircularProgress/>}

      <div className={classes.root}>
        {renderHeader()}
        {renderControlPointsList()}
        {renderFooter()}
        {renderSaveConfirmationDialog()}
      </div>
    </>
  );
}

export default ViewMachineCenter;