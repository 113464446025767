import {axios} from '../client';

import {
  SST_API_PATH_CUSTOMERS,
  QUERY_IN, 
  QUERY_COMMA, 
  QUERY_GREATERTHAN, 
  QUERY_LESSTHAN, 
  QUERY_AND,
  filterFormats
} from './../../Constants';

export const constructFilterString = (filterOptions) => {
  let filterString = '';

  if(!!(filterOptions)) {
      if(filterOptions.some((filter) => filter.filterBy.length > 0 || (Object.values(filter.filterBy).some(value => !!(value))))) {
          filterOptions.forEach((filterOption, i) => {
              const { filterBy, id } = filterOption;

              if (filterBy.length === 0) return;
          
              const addInClause = (field) => {
                let filterIds = [];
                filterIds = filterBy.flatMap(item => Object.values(item));
                filterString += `${field}${QUERY_IN}${filterIds.join(QUERY_COMMA)}`;
              };
          
              switch (id) {
                  case 0:
                      addInClause('plantId');
                      break;
                  case 1:
                      addInClause('lineId');
                      break;
                  case 2:
                      addInClause('machineCenterId');
                      break;
                  case 5:
                      addInClause('droneModel');
                      break;
                  case 3:
                      addInClause('areaId');
                      break;
                  case 4:
                      let sizeOfdateFilters = Object.values(filterBy).filter((f => !!(f))).length; // Determine how many date filters there are.
                      Object.values(filterBy).forEach((filterValue, index) => {
                          if(filterValue) {
                            filterString += 'dateRecorded';
                            filterString += index === 0 ? QUERY_GREATERTHAN + filterValue : QUERY_LESSTHAN + filterValue;
                            if (index === 0 && sizeOfdateFilters > 1) {
                                filterString += QUERY_AND;   
                            }
                          }
                      });
                      break;
                  default:
                      break;
              }
          
              // Add QUERY_AND if there are more non-empty filters following this one
              if (i < filterOptions.length - 1 && filterOptions.slice(i + 1).some(item => item.type === filterFormats.DATE ? (item.filterBy.start || item.filterBy.end) : item.filterBy.length > 0)) {
                  filterString += QUERY_AND;
              }
          });
      }
  }
  return filterString;
}

export const getAllCustomers = ({queryKey}) => {
  const [, , queryParams] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}`, {
    params: queryParams
  }).then(res => res.data);  
}

export const getCustomer = ({queryKey}) => {
  const [, {customerId}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}`).then(res => res.data);  
}

export const updateCustomer = (customer) => {
  const customerId = customer.id

  if(!customer.customerTypeId){
    delete customer.customerTypeId;
  }

  return axios.put(`${SST_API_PATH_CUSTOMERS}/${customerId}`, customer).then(res => res.data);
}

export const createCustomer = (customer) => {
  const customerCopy = {...customer}
  if(!customerCopy.customerTypeId){
    delete customerCopy.customerTypeId;
  }
  return axios.post(`${SST_API_PATH_CUSTOMERS}`, customerCopy).then(res => res.data);
}

export const getCustomerTypes = () => {
  return axios.get(`${SST_API_PATH_CUSTOMERS}/types`).then(res => res.data);
}
export const getCustomerPlants = ({queryKey}) => {
  const [, {customerId}, queryParams ={}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/plants`, {
    params: queryParams
  })
  .then(res => res.data);
}

export const getCustomerDashboards = ({queryKey}) => {
  const [, {customerId}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/dashboards`)
  .then(res => res.data);
}

export const getCustomerLines = ({queryKey}) => {
  const [, {customerId}, queryParams={}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/lines`, {
    params: queryParams
  })
  .then(res => res.data);
}

export const getCustomerMachineCenters = ({queryKey}) => {
  const [, {customerId}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/machine-centers`)
  .then(res => res.data);
}

export const getCustomerControlPoints = ({queryKey}) => {
  const [, {customerId}, queryParams={}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/control-points`, {
    params: queryParams
  })
  .then(res => res.data);
}

export const getCustomerDroneModels = ({queryKey}) => {
  const [, {customerId}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/drones/models`)
  .then(res => res.data);
}

export const getCustomerAreas = ({queryKey}) => {
  const [, {customerId}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/areas`)
  .then(res => res.data);
}

export const getCustomerUsers = ({queryKey}) => {
  const [, {customerId}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/users`)
  .then(res => res.data);
}

export const getCustomerLinesQuery = ({queryKey}) => {
  const [, {customerId}, {lineId}, {sortBy}, {sortOrder}, {pageSize}, {page}, {filter}] = queryKey;
  return axios.request({
    method: 'POST',
    url: `${SST_API_PATH_CUSTOMERS}/${customerId}/lines/${lineId}/runs/$query`,
    data: {
      sortBy: sortBy,
      sortOrder: sortOrder,
      pageSize: pageSize,
      page: page,
      filter: filter
    }
  })

}

export const getCustomerRunsQuery = ({queryKey}) => {
  const [, {customerId}, {sortBy}, {sortOrder}, {pageSize}, {page}, {filter}, {includeControlChartData}] = queryKey;
  return axios.request({
    method: 'POST',
    url: `${SST_API_PATH_CUSTOMERS}/${customerId}/runs/$query`,
    data: {
      sortBy: sortBy,
      sortOrder: sortOrder,
      pageSize: pageSize,
      page: page,
      filter: filter,
      includeControlChartData: includeControlChartData
    }
  })

}