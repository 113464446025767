import { useContext, useEffect, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Typography } from '@mui/material'
import translations from '../../../translations/en.json';
import useStyles from './Styles.js';
import {
  SST_PAGE_LIST_RUNS,
  SST_PAGE_EDIT_MACHINE_CENTER,
  SST_PAGE_MACHINE_CENTER_SETTINGS,
  SST_PAGE_CREATE_CONTROL_POINT,
  SST_PAGE_REORDER_CONTROL_POINTS,
  CONTROL_POINT_SECTION_STATES,
  SST_PAGE_EDIT_CONTROL_POINT,
  SST_PAGE_CONTROL_POINT_SETTINGS,
  SST_PAGE_VIEW_CONTROL_POINT, SST_PAGE_LIST_MACHINE_CENTERS
} from '../../../Constants'
import { useQuery } from 'react-query'
import PageHeader from '../../../shared/components/PageHeader/PageHeader'
import * as React from 'react'
import ProgressButton, { PROGRESS_BUTTON_VARIANTS } from '../../../shared/components/ProgressButton/ProgressButton'
import {
  DATE_TIME_TIMEZONE_FORMAT,
  handlePermissionRedirect,
  PERMISSION_METHOD_GET,
} from '../../../shared/Utilities'
import { SiteContext } from '../../../Context'
import QuantifeelSvgIcon from '../../../shared/components/QuantifeelSvgIcon/QuantifeelSvgIcon'
import {ReactComponent as EditIcon} from '../../../img/icons/edit.svg'
import {ReactComponent as SettingsIcon} from '../../../img/icons/settings.svg'
import {ReactComponent as CheckIcon} from '../../../img/icons/checked.svg'
import {ReactComponent as CrossIcon} from '../../../img/icons/cross.svg'
import EnhancedTable from '../../../shared/components/EnhancedTable/EnhancedTable'
import { getMachineCenter } from '../../../query/entities/machineCenters'
import { getControlPoints } from '../../../query/entities/controlPoints'
import ActiveStatusIndicator from '../../../shared/components/ActiveStatusIndicator/ActiveStatusIndicator'
import CreateButton from '../../../shared/components/CreateButton'
import QuantifeelTooltip from '../../../shared/components/QuantifeelTooltip'
import ProtectedMoment from '../../../shared/components/ProtectedMoment/ProtectedMoment'
import CopyLabel from '../../../shared/components/CopyLabel/CopyLabel'
import { getUnifiedControlChartStatus } from '../../../shared/ControlChartingUtils'
import FullScreenCircularProgress from '../../../shared/components/FullScreenCircularProgress'

const pageTitle = translations.pages.viewMachineCenter.viewMachineCenter;

const acceptablePagePermission = [
  {entity: 'Line', method: PERMISSION_METHOD_GET, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const METADATA_COLUMN_KEYS = {
  KEY: "label",
  VALUE: "content",
};

const metadataColumns = [
  {id: METADATA_COLUMN_KEYS.KEY, label: translations.pages.machineCenterSettings.metadataTableKey},
  {id: METADATA_COLUMN_KEYS.VALUE, label: translations.pages.machineCenterSettings.metadataTableValue},
]

const CONTROL_POINT_COLUMN_KEYS = {
  INDEX: "index",
  NAME: "name",
  LATEST_RUN: "latestRun",
  RESULT: "result",
  ACTIVE_STATUS: "status",
  ACTIONS: "actions",
}

const controlPointsColumns = [
  {id: CONTROL_POINT_COLUMN_KEYS.INDEX, label: translations.pages.viewMachineCenter.index},
  {id: CONTROL_POINT_COLUMN_KEYS.NAME, label: translations.common.controlPoints.controlPoint, width: "300px"},
  {id: CONTROL_POINT_COLUMN_KEYS.LATEST_RUN, label: translations.common.latestRun, width: "205px"},
  {id: CONTROL_POINT_COLUMN_KEYS.RESULT, label: translations.pages.viewMachineCenter.result, width: "200px"},
  {id: CONTROL_POINT_COLUMN_KEYS.ACTIVE_STATUS, label: translations.common.status},
  {id: CONTROL_POINT_COLUMN_KEYS.ACTIONS, label: translations.common.actions, disableSort: true},
]

const ViewMachineCenter = (props) => {

  const {
    history
  } = props;

  const { userDetails, hasPermission } = useContext(SiteContext);

  const match = useRouteMatch();
  const machineCenterId = match.params.machineCenterId;

  const [isLoading, setIsLoading] = useState(false);

  // ------------------------------
  // -- BEGIN useQuery / useMemo --
  // ------------------------------

  const {isLoading: isLoadingMachineCenter, data: machineCenter= {}} = useQuery(
    ['machineCenter', {machineCenterId: machineCenterId}],
    getMachineCenter,
    {enabled: !!machineCenterId}
  );

  const {isLoading: isLoadingControlPoints, data: controlPoints= []} = useQuery(
    ['controlPoints', {machineCenterId: machineCenterId, includeLatestQualifierRun: true}],
    getControlPoints,
    {enabled: !!machineCenterId}
  );

  // ----------------------------
  // -- END useQuery / useMemo --
  // ----------------------------

  // ----------------------
  // -- BEGIN useEffects --
  // ----------------------

  /**
   * On component mount...
   */
  useEffect(() => {

    // Validate permissions...
    handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission)

    // Set page title..
    document.title = pageTitle;

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Given changes to loading states,
   * Set isLoading...
   */
  useEffect(() => {
    if ( isLoadingMachineCenter ||
         isLoadingControlPoints ) {
      setIsLoading(true)
    }
    else {
      setIsLoading(false)
    }
  }, [isLoadingMachineCenter, isLoadingControlPoints])

  // --------------------
  // -- END useEffects --
  // --------------------

  const generateMetadataTableRows = (metadata = []) => {
    return metadata.map((md) => {
      return {

        [METADATA_COLUMN_KEYS.KEY]:
          <Typography rawval={md.key.toLowerCase()}>
            {md.key}
          </Typography>,

        [METADATA_COLUMN_KEYS.VALUE]:
          <Typography rawval={md.value.toLowerCase()}>
            {md.value}
          </Typography>

      }
    });
  }

  const generateControlPointsTableRows = (controlPoints = []) => {
    return controlPoints.map((controlPoint) => {
      return {

        [CONTROL_POINT_COLUMN_KEYS.INDEX]:
          controlPoint.controlPointIndex,

        [CONTROL_POINT_COLUMN_KEYS.NAME]:
          <CopyLabel value={controlPoint.id} rawval={controlPoint.name?.toLowerCase()}>
            <QuantifeelTooltip title={translations.common.controlPoints.viewControlPoint}>
              <Link
                className={classes.textOverflowHiddenEllipsis}
                to={`/${SST_PAGE_VIEW_CONTROL_POINT}/${controlPoint.id}`}>
                  {controlPoint.name}
              </Link>
            </QuantifeelTooltip>
          </CopyLabel>,

        [CONTROL_POINT_COLUMN_KEYS.LATEST_RUN]:
          <ProtectedMoment
            date={controlPoint.latestRun?.dateRecorded}
            format={DATE_TIME_TIMEZONE_FORMAT}
            rawval={controlPoint.latestRun?.dateRecorded}
            tz={userDetails.user.tz}
          />,

        [CONTROL_POINT_COLUMN_KEYS.RESULT]: (() => {

          const controlPointLatestRunControlChartStats = controlPoint.latestRun?.controlChartStats[0]; // .controlChartStats[0] since response only includes data for the given control point...

          // Validate...
          if (!controlPointLatestRunControlChartStats) { return <Typography>-</Typography> }

          // Get unified status...
          const unifiedStatus  = getUnifiedControlChartStatus({
            controlLimitStatus: controlPointLatestRunControlChartStats?.controlLimitStatus,
            specificationLimitStatus: controlPointLatestRunControlChartStats?.specLimitStatus
          })

          // Derive icon...
          switch(unifiedStatus) {
            case CONTROL_POINT_SECTION_STATES.IN_CONTROL.key:
              return <QuantifeelSvgIcon
                rawval={unifiedStatus} // To enable sorting...
                component={CheckIcon}
                viewBox="0 0 32 32"
                tooltipTitle={unifiedStatus}
              />
            case CONTROL_POINT_SECTION_STATES.OUT_OF_CONTROL.key:
              return <QuantifeelSvgIcon
                rawval={unifiedStatus} // To enable sorting...
                component={CrossIcon}
                viewBox="0 0 33 32"
                tooltipTitle={unifiedStatus}
              />
            // case CONTROL_POINT_SECTION_STATES.INSUFFICIENT_DATA.key:
            // case CONTROL_POINT_SECTION_STATES.DIDNT_SEE_CONTROL_POINT.key:
            default:
              return <QuantifeelTooltip
                rawval="-"
                title={unifiedStatus}>
                  <Typography>-</Typography>
              </QuantifeelTooltip>
          }
        })(),

        [CONTROL_POINT_COLUMN_KEYS.ACTIVE_STATUS]:
          <ActiveStatusIndicator
            rawval={controlPoint.inactive ? "inactive" : "active"} // To enable sorting...
            isActive={!controlPoint.inactive}
          />,

        [CONTROL_POINT_COLUMN_KEYS.ACTIONS]:
          <div className={classes.machineCenterDetailsActionsContainer}>
            <Link to={`/${SST_PAGE_EDIT_CONTROL_POINT}?machineCenterId=${machineCenterId}&controlPointId=${controlPoint.id}`}>
              <QuantifeelSvgIcon
                component={EditIcon}
                viewBox="0 0 32 32"
                tooltipTitle={translations.common.controlPoints.editControlPoint}
              />
            </Link>
            <Link to={`/${SST_PAGE_CONTROL_POINT_SETTINGS}?machineCenterId=${machineCenterId}&controlPointId=${controlPoint.id}`}>
              <QuantifeelSvgIcon
                component={SettingsIcon}
                viewBox="0 0 32 32"
                style={{fill: 'black'}}
                tooltipTitle={translations.common.controlPoints.controlPointSettings}
              />
            </Link>
          </div>

      }
    });
  }


  // --------------------------
  // -- BEGIN event handlers --
  // --------------------------

  const onBack = () => {
    // If there is a page in history, go back, else, go to /list-machine-centers...
    return !!(history.location.key) ? history.goBack() : history.push(`/${SST_PAGE_LIST_MACHINE_CENTERS}`)
  }

  // ------------------------
  // -- END event handlers --
  // ------------------------

  // -------------------
  // -- BEGIN renders --
  // -------------------

  const classes = useStyles();

  const renderHeader = () => {

    const contentRight = (
      <div className={classes.headerButtonContainer}>

        {/* Runs List */}
        <ProgressButton
          variant={PROGRESS_BUTTON_VARIANTS.ACTION}
          text={translations.pages.listRuns.title}
          onClick={() => history.push(`/${SST_PAGE_LIST_RUNS}?machineCenterId=${machineCenterId}`)}
        />

      </div>
    )

    return (
      <PageHeader
        onBack={onBack}
        pageTitle={`${machineCenter.name} ${translations.common.info}`}
        contentRight={contentRight}
      />
    )
  }

  const renderDetails = () => {
    return (
      <>
        <div className={classes.machineCenterDetailsStyle}>
          <div className={classes.machineCenterHeadingStyle}><Typography className={classes.machineCenterDetailsHeader}>{translations.common.machineCenters.machineCenter}</Typography></div>
          <div className={classes.machineCenterHeadingStyle}><Typography className={classes.machineCenterDetailsHeader}>{translations.common.controlPoints.numControlPoints}</Typography></div>
          <div className={classes.machineCenterHeadingStyle}><Typography className={classes.machineCenterDetailsHeader}>{translations.common.status}</Typography></div>
          <div className={classes.machineCenterHeadingStyle} style={{flex: .5}}/> { /* Override flex, from 1 to .5, to ensure actions element take up less real estate. */ }
        </div>

        <div className={classes.machineCenterDetailsStyle}>
          <div className={classes.machineCenterHeadingStyle}><Typography>{machineCenter.name}</Typography></div>
          <div className={classes.machineCenterHeadingStyle}><Typography>{controlPoints.length}</Typography></div>
          <div className={classes.machineCenterHeadingStyle}><ActiveStatusIndicator isActive={!machineCenter.inactive}/></div>
          <div className={classes.machineCenterHeadingStyle} style={{flex: .5}}> { /* Override flex, from 1 to .5, to ensure actions element take up less real estate. */ }

            <div className={classes.machineCenterDetailsActionsContainer}>
              <Link to={`/${SST_PAGE_EDIT_MACHINE_CENTER}?machineCenterId=${machineCenter.id}`}>
                <QuantifeelSvgIcon
                  component={EditIcon}
                  viewBox="0 0 32 32"
                  tooltipTitle={translations.common.machineCenters.editMachineCenter}
                />
              </Link>
              <Link to={`/${SST_PAGE_MACHINE_CENTER_SETTINGS}/${machineCenter.id}`}>
                <QuantifeelSvgIcon
                  component={SettingsIcon}
                  viewBox="0 0 32 32"
                  style={{fill: 'black'}}
                  tooltipTitle={translations.common.machineCenters.machineCenterSettings}
                />
              </Link>
            </div>

          </div>
        </div>
      </>
    )
  }

  const renderMetadataTable = () => {
    return (
      <div className={classes.tableContainer}>
        <Typography variant='h6'>{translations.pages.viewMachineCenter.metadataProperties}</Typography>
        <EnhancedTable
          order={'asc'}
          orderBy={METADATA_COLUMN_KEYS.KEY}
          rows={generateMetadataTableRows(machineCenter.machineCenterMetadata)}
          headCells={metadataColumns}
          isLoading={isLoadingMachineCenter}
          enableSearch={false}
        />
      </div>
    )
  }

  const renderControlPointsTable = () => {
    return (
      <div className={classes.tableContainer}>

        {/* Header */}
        <div className={classes.tableContainerHeader}>

          {/* Label */}
          <Typography variant='h6'>{translations.common.controlPoints.controlPoints}</Typography>

          {/* Buttons */}
          <div className={classes.tableHeaderButtonContainer}>

            { hasPermission('Line', 'insert') &&
              machineCenter.machineCenterType === undefined && // If Machine Center is NOT typed...
                <>

                  {/* Create Control Point */}
                  <CreateButton
                    onClick={() => history.push(`/${SST_PAGE_CREATE_CONTROL_POINT}?machineCenterId=${machineCenterId}`)}
                    label={translations.common.controlPoints.createControlPoint}
                  />

                  {/* Reorder Control Points */}
                  <ProgressButton
                    variant={PROGRESS_BUTTON_VARIANTS.ACTION}
                    onClick={() => history.push(`/${SST_PAGE_REORDER_CONTROL_POINTS}?machineCenterId=${machineCenterId}`)}
                    text={translations.common.controlPoints.reOrderControlPoints}
                  />

                </>
            }

          </div>

        </div>

        {/* Table */}
        <EnhancedTable
          order={'asc'}
          orderBy={CONTROL_POINT_COLUMN_KEYS.INDEX}
          rows={generateControlPointsTableRows(controlPoints)}
          headCells={controlPointsColumns}
          isLoading={isLoadingControlPoints}
          enableSearch={false}
        />
      </div>
    )
  }

  return (
    <>
      {isLoading && <FullScreenCircularProgress/>}

      <div className={classes.root}>

        {renderHeader()}
        {renderDetails()}

        <div className={classes.tablesContainer}>
          {renderMetadataTable()}
          {renderControlPointsTable()}
        </div>

      </div>

    </>
  );
}

export default ViewMachineCenter;