import React, { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { SiteContext } from '../../Context'
import { getAllCustomers, getHubs } from '../../query/queries'
import CopyLabel from '../../shared/components/CopyLabel/CopyLabel'
import EnhancedTable from '../../shared/components/EnhancedTable/EnhancedTable'
import ProtectedMoment from '../../shared/components/ProtectedMoment/ProtectedMoment'
import {
  DATE_TIME_FORMAT,
  handlePermissionRedirect,
  PERMISSION_METHOD_GET,
} from '../../shared/Utilities'
import '../PageStyles/TablePage.css'
import CreateButton from "../../shared/components/CreateButton";

const pageTitle = 'List Hubs'
const acceptablePagePermission = [
  { entity: 'Hub', method: PERMISSION_METHOD_GET, modifier: '' },
  { entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children' },
]

const columns = [
  { id: 'name', label: 'Name', width: '380px' },
  { id: 'customerName', label: 'Customer', width: '200px' },
  { id: 'imei', label: 'IMEI', width: '150px' },
  { id: 'iccid', label: 'SIM', width: '190px' },
  { id: 'dateCreated', label: 'Created', width: '175px' },
  { id: 'lastSeen', label: 'Last Seen', width: '210px' },
  { id: 'actions', label: 'Actions', width: '120px' },
]

const generateRows = (hubs, customers, details) => {
  const customerMap = customers.reduce((memo, value) => {
    memo[value.id] = value.name
    return memo
  }, {})

  return hubs.map((hub) => {
    return {
      name: (
        <CopyLabel value={hub.id} rawval={hub.name.toLowerCase()}>
          {hub.name}
        </CopyLabel>
      ),
      customerName: customerMap[hub.customerId] || '',
      imei: hub.imei,
      iccid: hub.iccid,
      dateCreated: (
        <ProtectedMoment
          date={hub.dateCreated}
          format={DATE_TIME_FORMAT + ' z'}
          rawval={hub.dateCreated}
        />
      ),
      lastSeen:
        'dateLastSeenRecord' in hub ? (
          <div rawval={hub.dateLastSeenRecord}>
            <ProtectedMoment
              format={DATE_TIME_FORMAT + ' z'}
              date={hub.dateLastSeenRecord}
              tz={details.user.tz}
            />
            <br />
            <i>
              <ProtectedMoment from={hub.dateLastSeenRecord} toNow ago /> ago
            </i>
          </div>
        ) : (
          <div rawval={'0'}>Never</div>
        ),
      actions: (
        <div>
          <Link to={'edit-hub?hubId=' + hub.id}>Details</Link>
          <br />
          <Link to={'hub-logs?imei=' + hub.imei}>Show hub logs</Link>
        </div>
      ),
    }
  })
}

const ListHubs = (props) => {
  const { setBreadcrumbs, hasPermission, userDetails } = useContext(SiteContext)

  const { isLoading: isLoadingHubs, data: hubs = [] } = useQuery(
    ['hubs', {}, { detailed: true }],
    getHubs
  )
  const { isLoading: isLoadingCustomers, data: customers = [] } = useQuery(
    ['customers'],
    getAllCustomers
  )

  useEffect(() => {
    document.title = pageTitle
    setBreadcrumbs([
      {
        title: pageTitle,
      },
    ])
  }, [setBreadcrumbs])

  const onCreateButtonClick = (e) => {
    props.history.push('/create-hub')
  }

  return (
    <div className="page" data-testid="list-hubs-page">
      {handlePermissionRedirect(
        pageTitle,
        props.history,
        hasPermission,
        acceptablePagePermission
      ) && (
        <div className="container">
          {hasPermission('Hub', 'insert') && (
            <div className="createButton">
              <CreateButton
                onClick={onCreateButtonClick}
                isLoading={isLoadingHubs || isLoadingCustomers}
                label={'Create Hub'}
              />
            </div>
          )}
          <EnhancedTable
            title="Hubs"
            isLoading={isLoadingHubs || isLoadingCustomers}
            order={'asc'}
            orderBy={'name'}
            rows={generateRows(hubs, customers, userDetails)}
            headCells={columns}
            enableSearch={true}
            searchColumns={['name', 'customerName', 'imei', 'iccid']}
          />
        </div>
      )}
    </div>
  )
}

export default ListHubs
