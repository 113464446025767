import * as React from "react";
import { useContext, useEffect, useMemo, useState } from 'react'
import {Link} from 'react-router-dom';
import {SiteContext} from "../../Context";
import { MenuItem, Typography } from '@mui/material'
import {makeStyles} from '@mui/styles';
import SSTDropdown from "../../shared/components/SSTDropdown";
import EnhancedTable from "../../shared/components/EnhancedTable/EnhancedTable";
import CopyLabel from "../../shared/components/CopyLabel/CopyLabel";
import {ReactComponent as EditIcon} from '../../img/icons/edit.svg'
import {ReactComponent as SettingsIcon} from '../../img/icons/settings.svg'
import translations from '../../translations/en.json';

import {useQuery} from 'react-query';
import {
  getCustomerPlants,
  getCustomerLines,
  getCustomerMachineCenters,
  getCustomerControlPoints,
} from '../../query/queries'

import '../PageStyles/TablePage.css';

import {
  DATE_TIME_TIMEZONE_FORMAT,
  handlePermissionRedirect,
  PERMISSION_METHOD_GET,
  PERMISSION_METHOD_UPDATE,
} from '../../shared/Utilities'

import {
  SST_PAGE_CONTROL_POINT_SETTINGS,
  SST_PAGE_CREATE_CONTROL_POINT,
  SST_PAGE_EDIT_CONTROL_POINT, SST_PAGE_VIEW_CONTROL_POINT,
} from '../../Constants'
import CreateButton from '../../shared/components/CreateButton'
import QuantifeelSvgIcon from '../../shared/components/QuantifeelSvgIcon/QuantifeelSvgIcon'
import { v4 as uuidv4 } from 'uuid';
import ActiveStatusIndicator from '../../shared/components/ActiveStatusIndicator/ActiveStatusIndicator'
import ProtectedMoment from '../../shared/components/ProtectedMoment/ProtectedMoment'
import QuantifeelTooltip from '../../shared/components/QuantifeelTooltip'

const pageTitle = translations.pages.listControlPoints.title;
const acceptablePagePermission = [
  {entity: 'Line', method: PERMISSION_METHOD_UPDATE, modifier: ''},
  {entity: 'Plant', method: PERMISSION_METHOD_UPDATE, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const COLUMN_IDS = {
  CONTROL_POINT: "controlPoint",
  MACHINE_CENTER: "machineCenter",
  PLANT: "plant",
  LINE: "line",
  LATEST_RUN: "latestRun",
  ACTIVE_STATUS: "activeStatus",
  ACTIONS: "actions"
};

const columns = [
  {id: COLUMN_IDS.CONTROL_POINT, label: translations.common.controlPoints.controlPoint, width: "300px"},
  {id: COLUMN_IDS.MACHINE_CENTER, label: translations.common.machineCenters.machineCenter, width: "200px"},
  {id: COLUMN_IDS.PLANT, label: translations.common.plants.plant, width: "200px"},
  {id: COLUMN_IDS.LINE, label: translations.common.lines.line, width: "200px"},
  {id: COLUMN_IDS.LATEST_RUN, label: translations.common.latestRun, width: "205px"},
  {id: COLUMN_IDS.ACTIVE_STATUS, label: translations.common.status},
  {id: COLUMN_IDS.ACTIONS, label: translations.common.actions, disableSort: true}
]

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    minWidth: '350px',
    paddingBottom: theme.spacing(1)
  },
  leftHeaderControlsContainer: {
    display: 'flex',
    flex: 1,
    marginRight: theme.spacing(3),

    '& > :not(:last-child)': {
      marginRight: theme.spacing(3)
    },

    flexWrap: 'wrap'
  },
  rightHeaderControlsContainer: {
    display: 'flex',
    height: '100%',
    paddingTop: 10
  },
  textOverflowHiddenEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  actionsContainer: {
    '& > *': {
      marginRight: theme.spacing(3)
    }
  }
}));

const ALL_MENU_ITEM = uuidv4();

const ListControlPoints = (props) => {

  const {
    history
  } = props;

  const {currentCustomer, pageFilters, setPageFilters, userDetails, hasPermission} = useContext(SiteContext);
  const [selectedPlant, setSelectedPlant] = useState(pageFilters?.plant || ALL_MENU_ITEM);
  const [selectedLine, setSelectedLine] = useState( pageFilters?.line || ALL_MENU_ITEM);
  const [selectedMachineCenter, setSelectedMachineCenter] = useState( pageFilters?.machineCenter || ALL_MENU_ITEM);
  const [filteredEnrichedControlPoints, setFilteredEnrichedControlPoints] = useState([]);
  const [resetTablePagination, setResetTablePagination] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // ------------------------------
  // -- BEGIN useQuery / useMemo --
  // ------------------------------

  const {
    isLoading: isLoadingPlants,
    data: plants = []
  } = useQuery(['plants', {customerId: currentCustomer}], getCustomerPlants);

  // GET lines, including deleted lines and plant name, for purposes of enriching control points with plantName and lineName, regardless of if the parent line is deleted...
  const {
    isLoading: isLoadingLinesIncludedDeleted,
    data: linesIncludeDeleted = []
  } = useQuery(['lines', {customerId: currentCustomer}, {includeDeleted: true, includePlantName: true}], getCustomerLines);

  const linesOmitDeleted = useMemo(() => {
    return linesIncludeDeleted.filter((line) => { return !line.isDeleted });
  }, [linesIncludeDeleted]);

  const {
    isLoading: isLoadingMachineCenters,
    data: machineCenters = []
  } = useQuery(['machineCenters', {customerId: currentCustomer}], getCustomerMachineCenters);

  const {
    isLoading: isLoadingControlPoints,
    data: controlPoints = []
  } = useQuery(['controlPoints', {customerId: currentCustomer}, {includeLatestQualifierRun: true}], getCustomerControlPoints);

  const enrichedControlPoints = useMemo(() => {
    return [...controlPoints]
  }, [controlPoints]);

  // ----------------------------
  // -- END useQuery / useMemo --
  // ----------------------------

  // ----------------------
  // -- BEGIN useEffects --
  // ----------------------

  /**
   * On component mount...
   */
  useEffect(() => {

    // Validate permissions...
    handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission)

    // Set page title..
    document.title = pageTitle;
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  /**
   * Given changes to loading states,
   * Set isLoading...
   */
  useEffect(() => {
    if ( isLoadingPlants ||
         isLoadingLinesIncludedDeleted ||
         isLoadingMachineCenters ||
         isLoadingControlPoints) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isLoadingPlants, isLoadingLinesIncludedDeleted, isLoadingMachineCenters, isLoadingControlPoints])
  
  /**
   * Given changes to machineCenters, linesIncludeDeleted, enrichedControlPoints,
   * Enrich control points w/ plantName, lineName, machineCenterName, and lastQualificationDate...
   */
  useEffect(() => {

    // Validate machineCenters, lines, enrichedControlPoints...
    if ( machineCenters.length === 0 ||
         linesIncludeDeleted.length === 0 ||
         enrichedControlPoints.length === 0 ) {
      return;
    }

    // For each enrichedControlPoint, enrich control point w/ machineCenterName, plantName, lineName...
    enrichedControlPoints.forEach((enrichedControlPoint) => {

      // Find machine center associated to control point, on machineCenter.id === enrichedControlPoint.machineCenterId...
      const machineCenterAssociatedToControlPoint = machineCenters.find((machineCenter) => { return machineCenter.id === enrichedControlPoint.machineCenterId });

      // Find line associated to machine center, on line.id === machineCenterAssociatedToControlPoint.lineId...
      const lineAssociatedToMachineCenter = linesIncludeDeleted.find((line) => { return line.id === machineCenterAssociatedToControlPoint.lineId });

      // -------------------------
      // -- MACHINE CENTER NAME --
      // -------------------------

      enrichedControlPoint.machineCenterName = machineCenterAssociatedToControlPoint.name

      // ----------------
      // -- PLANT ID / NAME --
      // ----------------

      enrichedControlPoint.plantId = lineAssociatedToMachineCenter.plantId
      enrichedControlPoint.plantName = lineAssociatedToMachineCenter.plantName

      // ----------------
      // -- LINE ID / NAME --
      // ----------------

      enrichedControlPoint.lineId = lineAssociatedToMachineCenter.id
      enrichedControlPoint.lineName = lineAssociatedToMachineCenter.name

    })

  }, [machineCenters, linesIncludeDeleted, enrichedControlPoints])

  /**
   * Given changes to selectedPlant, selectedLine, selectedMachineCenter, enrichedControlPoints,
   * Filter enrichedMachineCenters...
   */
  useEffect(() => {

    // Validate deps...
    if (enrichedControlPoints.length === 0) {
      return;
    }

    // If selectedPlant and selectedLine are ALL_MENU_ITEM...
    if ( selectedPlant === ALL_MENU_ITEM &&
         selectedLine  === ALL_MENU_ITEM &&
         selectedMachineCenter === ALL_MENU_ITEM ) {
      setFilteredEnrichedControlPoints(enrichedControlPoints);
      return;
    }

    // If selectedPlant, selectedLine, and selectedMachineCenter are not ALL_MENU_ITEM...
    // Filter control points to only include control points from selectedPlant and / or selectedLine and selectedMachineCenter...
    const filterPlant = selectedPlant !== ALL_MENU_ITEM;
    const filterLine = selectedLine  !== ALL_MENU_ITEM;
    const filterMachineCenter = selectedMachineCenter !== ALL_MENU_ITEM;

    let filteredControlPoints = enrichedControlPoints;
    filteredControlPoints = filterPlant ? filteredControlPoints.filter((controlPoint) => { return controlPoint.plantId === selectedPlant }) : filteredControlPoints;
    filteredControlPoints = filterLine ? filteredControlPoints.filter((controlPoint) => { return controlPoint.lineId === selectedLine }) : filteredControlPoints;
    filteredControlPoints = filterMachineCenter ? filteredControlPoints.filter((controlPoint) => { return controlPoint.machineCenterId === selectedMachineCenter }) : filteredControlPoints;
    setFilteredEnrichedControlPoints(filteredControlPoints);

  }, [selectedPlant, selectedLine, selectedMachineCenter, enrichedControlPoints]);

  /**
   * Given changes to selectedPlant,
   * Determine if selectedLine, selectedMachineCenter should be updated, to avoid invalid selectedPlant / selectedLine / selectedMachineCenter combinations...
   */
  useEffect(() => {

    // If selectedLine / selectedMachineCenter === ALL_MENU_ITEM, no change required, so return...
    if ( selectedLine === ALL_MENU_ITEM &&
         selectedMachineCenter === ALL_MENU_ITEM ) {
      return;
    }

    const selectedLineObject = linesOmitDeleted.find((line) => { return line.id === selectedLine });
    const selectedMachineCenterObject = machineCenters.find((machineCenter) => { return machineCenter.id === selectedMachineCenter });

    // If selectedPlant is not ALL_MENU_ITEM and is not a parent of selectedLine, set selectedLine to ALL_MENU_ITEM...
    if ( selectedPlant !== ALL_MENU_ITEM &&
         selectedPlant !== selectedLineObject?.plantId ) {
      handleSetSelectedLineFromId(ALL_MENU_ITEM);
    }

    // If selectedPlant is not ALL_MENU_ITEM and is not a parent of selectedMachineCenter, set selectedMachineCenter to ALL_MENU_ITEM...
    if ( selectedPlant !== ALL_MENU_ITEM &&
         selectedPlant !== selectedMachineCenterObject?.plantId ) {
      handleSetSelectedMachineCenterFromId(ALL_MENU_ITEM);
    }

  }, [linesOmitDeleted, machineCenters, selectedPlant]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Given changes to selectedLine,
   * Determine if selectedMachineCenter should be updated, to avoid invalid selectedLine / selectedMachineCenter combinations...
   */
  useEffect(() => {

    // If selectedMachineCenter === ALL_MENU_ITEM, no change required, so return...
    if (selectedMachineCenter === ALL_MENU_ITEM) {
      return;
    }

    const selectedMachineCenterObject = machineCenters.find((machineCenter) => { return machineCenter.id === selectedMachineCenter });

    // If selectedLine is not ALL_MENU_ITEM and is a parent of selectedMachineCenter, set selectedMachineCenter to ALL_MENU_ITEM...
    if ( selectedLine !== ALL_MENU_ITEM &&
         selectedLine !== selectedMachineCenterObject?.lineId ) {
      handleSetSelectedMachineCenterFromId(ALL_MENU_ITEM);
    }

  }, [machineCenters, selectedLine]); // eslint-disable-line react-hooks/exhaustive-deps

  // --------------------
  // -- END useEffects --
  // --------------------

  const generateRows = (controlPoints) => {
    return controlPoints.map((controlPoint) => {
      return {

        [COLUMN_IDS.CONTROL_POINT]:
          <CopyLabel value={controlPoint.id} rawval={controlPoint.name?.toLowerCase()}>
            <QuantifeelTooltip title={translations.common.controlPoints.viewControlPoint}>
              <Link
                className={classes.textOverflowHiddenEllipsis}
                to={`/${SST_PAGE_VIEW_CONTROL_POINT}/${controlPoint.id}`}>
                  {controlPoint.name}
              </Link>
            </QuantifeelTooltip>
          </CopyLabel>,

        [COLUMN_IDS.MACHINE_CENTER]:
          <CopyLabel value={controlPoint.machineCenterId} rawval={controlPoint.machineCenterName?.toLowerCase()}>
            <Typography className={classes.textOverflowHiddenEllipsis}>
              {controlPoint.machineCenterName}
            </Typography>
          </CopyLabel>,

        [COLUMN_IDS.PLANT]:
          <CopyLabel value={controlPoint.plantId} rawval={controlPoint.plantName?.toLowerCase()}>
            <Typography className={classes.textOverflowHiddenEllipsis}>
              {controlPoint.plantName}
            </Typography>
          </CopyLabel>,

        [COLUMN_IDS.LINE]:
          <CopyLabel value={controlPoint.lineId} rawval={controlPoint.lineName?.toLowerCase()}>
            <Typography className={classes.textOverflowHiddenEllipsis}>
              {controlPoint.lineName}
            </Typography>
          </CopyLabel>,

        [COLUMN_IDS.LATEST_RUN]:
          <ProtectedMoment
            date={controlPoint.latestRun?.dateRecorded}
            format={DATE_TIME_TIMEZONE_FORMAT}
            rawval={controlPoint.latestRun?.dateRecorded}
            tz={userDetails.user.tz}
          />,

        [COLUMN_IDS.ACTIVE_STATUS]:
          <ActiveStatusIndicator
            rawval={controlPoint.inactive ? "inactive" : "active"} // To enable sorting...
            isActive={!controlPoint.inactive}
          />,

        [COLUMN_IDS.ACTIONS]:
          <div className={classes.actionsContainer}>
            <Link to={`/${SST_PAGE_EDIT_CONTROL_POINT}?machineCenterId=${controlPoint.machineCenterId}&controlPointId=${controlPoint.id}`}>
              <QuantifeelSvgIcon
                component={EditIcon}
                viewBox="0 0 32 32"
                tooltipTitle={translations.common.controlPoints.editControlPoint}
              />
            </Link>
            <Link to={`/${SST_PAGE_CONTROL_POINT_SETTINGS}/${controlPoint.id}`}>
              <QuantifeelSvgIcon
                component={SettingsIcon}
                viewBox="0 0 32 32"
                style={{fill: 'black'}}
                tooltipTitle={translations.common.controlPoints.controlPointSettings}
              />
            </Link>
          </div>

      }
    });
  }

  const handleSetSelectedPlantFromEvent = e => {

    const selectedPlant = e.target.value;

    // Set selected plant...
    setSelectedPlant(selectedPlant);

    // Set pageFilters...
    if (selectedPlant === ALL_MENU_ITEM) {
      setPageFilters(f => ({ ...f, plant: undefined })); // Undefined since ALL_MENU_ITEM is not a plant. However, ALL_MENU_ITEM is default selectedPlant if !pageFilters...
    } else {
      setPageFilters(f => ({ ...f, plant: selectedPlant }));
    }

    // Reset table pagination...
    setResetTablePagination(true);
  };

  const handleSetSelectedLineFromEvent = e => {

    const lineId = e.target.value;

    handleSetSelectedLineFromId(lineId)
  };

  const handleSetSelectedMachineCenterFromEvent = e => {

    const machineCenterId = e.target.value;

    handleSetSelectedMachineCenterFromId(machineCenterId)
  };

  const handleSetSelectedLineFromId = (lineId) => {

    // Set selected plant...
    setSelectedLine(lineId);

    // Set pageFilters...
    if (lineId === ALL_MENU_ITEM) {
      setPageFilters(f => ({ ...f, line: undefined })); // Undefined since ALL_MENU_ITEM is not a line. However, ALL_MENU_ITEM is default selectedLine if !pageFilters...
    } else {
      setPageFilters(f => ({ ...f, line: lineId }));
    }

    // Reset table pagination...
    setResetTablePagination(true);
  };

  const handleSetSelectedMachineCenterFromId = (machineCenterId) => {

    // Set selected plant...
    setSelectedMachineCenter(machineCenterId);

    // Set pageFilters...
    if (machineCenterId === ALL_MENU_ITEM) {
      setPageFilters(f => ({ ...f, machineCenter: undefined })); // Undefined since ALL_MENU_ITEM is not a machineCenter. However, ALL_MENU_ITEM is default selectedMachineCenter if !pageFilters...
    } else {
      setPageFilters(f => ({ ...f, machineCenter: machineCenterId }));
    }

    // Reset table pagination...
    setResetTablePagination(true);
  };

  const getPlantDropdownMenuItems = (plants) => {

    // Add menu items...

    const menuItems = [];

    // a. Add "All" item...
    menuItems.push(<MenuItem key={ALL_MENU_ITEM} value={ALL_MENU_ITEM}>{translations.common.all}</MenuItem>)

    // b. Add item for each plant...
    plants.forEach((plant) => {
      menuItems.push(
        <MenuItem key={plant.id} value={plant.id}>{plant.name}</MenuItem>
      )
    })

    return menuItems
  }

  const getLineDropdownMenuItems = (lines) => {

    // Filter...

    let filteredLines = lines;

    // a. If selectedPlant !== ALL_MENU_ITEM, filter lines on plantId...
    if (selectedPlant !== ALL_MENU_ITEM) {
      filteredLines = lines.filter((line) => { return line.plantId === selectedPlant })
    }

    // Add menu items...

    const menuItems = [];

    // a. Add "All" item...
    menuItems.push(<MenuItem key={ALL_MENU_ITEM} value={ALL_MENU_ITEM}>{translations.common.all}</MenuItem>)

    // b. Add item for each filteredLine...
    filteredLines.forEach((filteredLine) => {
      menuItems.push(
        <MenuItem key={filteredLine.id} value={filteredLine.id}>{filteredLine.name}</MenuItem>
      )
    })

    return menuItems
  }

  const getMachineCenterDropdownMenuItems = (machineCenters) => {

    // Filter...

    let filteredMachineCenters = machineCenters;

    // a. If selectedPlant !== ALL_MENU_ITEM, filter machineCenters on plantId...
    if (selectedPlant !== ALL_MENU_ITEM) {
      filteredMachineCenters = machineCenters.filter((machineCenter) => { return machineCenter.plantId === selectedPlant })
    }

    // b. If selectedLine !== ALL_MENU_ITEM, further filter machineCenters on lineId...
    if (selectedLine !== ALL_MENU_ITEM) {
      filteredMachineCenters = filteredMachineCenters.filter((machineCenter) => { return machineCenter.lineId === selectedLine })
    }

    // Add menu items...

    const menuItems = [];

    // a. Add "All" item...
    menuItems.push(<MenuItem key={ALL_MENU_ITEM} value={ALL_MENU_ITEM}>{translations.common.all}</MenuItem>)

    // b. Add item for each filteredMachineCenter...
    filteredMachineCenters.forEach((filteredMachineCenter) => {
      menuItems.push(
        <MenuItem key={filteredMachineCenter.id} value={filteredMachineCenter.id}>{filteredMachineCenter.name}</MenuItem>
      )
    })

    return menuItems
  }

  const onCreateButtonClick = (e) => {

    // If selectedMachineCenter !== ALL_MENU_ITEM, navigate to create control point page with machineCenterId...
    if (selectedMachineCenter !== ALL_MENU_ITEM) {
      props.history.push(`/${SST_PAGE_CREATE_CONTROL_POINT}?machineCenterId=${selectedMachineCenter}`);
      return;
    }

    // If selectedLine !== ALL_MENU_ITEM, navigate to create control point page with lineId...
    if (selectedLine !== ALL_MENU_ITEM) {
      props.history.push(`/${SST_PAGE_CREATE_CONTROL_POINT}?lineId=${selectedLine}`);
      return;
    }

    // If selectedPlant !== ALL_MENU_ITEM, navigate to create control point page with plantId...
    if (selectedPlant !== ALL_MENU_ITEM) {
      props.history.push(`/${SST_PAGE_CREATE_CONTROL_POINT}?plantId=${selectedPlant}`);
      return;
    }

    props.history.push(`/${SST_PAGE_CREATE_CONTROL_POINT}`);
  };

  // -------------------
  // -- BEGIN renders --
  // -------------------

  const classes = useStyles();

  const renderHeader = () => {
    return (
      <div className={`headerContainer ${classes.headerContainer}`}>

        {/* Filter Controls */}
        <div className={classes.leftHeaderControlsContainer}>

          {/* Plant */}
          <SSTDropdown
            classes={classes}
            disabled={false}
            isLoadingContents={isLoadingPlants}
            label={translations.common.plants.plants}
            selectedValueId={selectedPlant}
            setValueFunc={handleSetSelectedPlantFromEvent}
            fullWidth={false}
            mappedList={getPlantDropdownMenuItems(plants)}
            required={false}
          />

          {/* Line */}
          <SSTDropdown
            classes={classes}
            disabled={false}
            isLoadingContents={isLoadingLinesIncludedDeleted}
            label={translations.common.lines.lines}
            selectedValueId={selectedLine}
            setValueFunc={handleSetSelectedLineFromEvent}
            fullWidth={false}
            mappedList={getLineDropdownMenuItems(linesOmitDeleted)}
            required={false}
          />

          {/* Machine Center */}
          <SSTDropdown
            classes={classes}
            disabled={false}
            isLoadingContents={isLoadingMachineCenters}
            label={translations.common.machineCenters.machineCenters}
            selectedValueId={selectedMachineCenter}
            setValueFunc={handleSetSelectedMachineCenterFromEvent}
            fullWidth={false}
            mappedList={getMachineCenterDropdownMenuItems(machineCenters)}
            required={false}
          />

        </div>

        {/* Create Button */}
        {hasPermission('Line', 'insert') &&
        <div className={classes.rightHeaderControlsContainer}>
          <div>
            <CreateButton
              onClick={onCreateButtonClick}
              isLoading={isLoading}
              label={translations.common.controlPoints.createControlPoint}
            />
          </div>
        </div>
        }

      </div>
    )
  }

  const renderTable = () => {
    return (
      <EnhancedTable
        order={'asc'}
        orderBy={COLUMN_IDS.CONTROL_POINT}
        rows={generateRows(filteredEnrichedControlPoints)}
        headCells={columns}
        isLoading={isLoading}
        enableSearch
        searchColumns={[COLUMN_IDS.CONTROL_POINT, COLUMN_IDS.MACHINE_CENTER, COLUMN_IDS.PLANT, COLUMN_IDS.LINE]}
        resetPagination={resetTablePagination}
        onResetPaginationComplete={() => setResetTablePagination(false)}
      />
    )
  }

  return (
    <div className="page" data-testid="list-machine-centers-page">
      <div className="container">
        {renderHeader()}
        {renderTable()}
      </div>
    </div>
  );
};

export default ListControlPoints