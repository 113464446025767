import { useContext, useEffect, useState } from "react";
import { Drawer, Accordion, AccordionSummary, AccordionDetails, Typography, Box, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment'
import Styles from "./Styles";
import ProgressButton, { PROGRESS_BUTTON_VARIANTS } from "../ProgressButton/ProgressButton";
import { SiteContext } from "../../../Context";
import { filterFormats, SST_PAGE_LIST_RUNS } from "../../../Constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import QuantifeelSvgIcon from "../QuantifeelSvgIcon/QuantifeelSvgIcon";
import {ReactComponent as CloseIcon} from '../../../img/icons/close.svg'

const FilterDrawer = (props) => {

    const { open, setOpen, filterAccessKey } = props;

    const history = useHistory();
    const {complexFilters, setComplexFilters} = useContext(SiteContext);
    const [updatedListOfFilters, setUpdatedListOfFilters] = useState([]);
    
    useEffect(() => {
      setUpdatedListOfFilters(complexFilters[filterAccessKey]);
    }, [complexFilters, filterAccessKey])

    const handleApply = () => {
      setComplexFilters((tables) => ({
        ...tables,
        [filterAccessKey]: updatedListOfFilters
      }));
      if(setOpen) {
          setOpen(false);
      }
    };

    const handleClear = () => {
        let newVal = updatedListOfFilters.map(fOption => {
          if(fOption.type === filterFormats.DATE) {
            return { ...fOption, filterBy: {start: null, end: null} };
          }
          return { ...fOption, filterBy: [] };
          }
        );

        setComplexFilters((tables) => ({
          ...tables,
          [filterAccessKey]: newVal
        }));
        // The replace here is to do with removing the filter in the params as opposed to re-rendering the page
        history.replace(`/${SST_PAGE_LIST_RUNS}`);
    }

    const handleRemovingDuplicates = (filter) => {
      if(filter.useNameAsFilter) {
        return filter.options;
      }
      return filter.options.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name));
    }

    const toggleDrawer = (newOpenState) => {
        if(!newOpenState) {
          setUpdatedListOfFilters(updatedListOfFilters.map((fOption, index) => 
            ({ ...fOption, filterBy: complexFilters[filterAccessKey][index].filterBy })
          ))
        }

        if(setOpen) {
            setOpen(newOpenState);
        }
    };

    const handleFilterUpdate = (category, newCheckedValue, option, valueIndex = null) => {
      // Filters are formatted as a dictionary of key/value pairs, {NAME_OF_FILTER: [VALUE]}
      // Note: The value is an array of ids (values to filter by - can be date, model number, etc.).
      /* Example of areas:
      [
        {
          "Auto Filler/Seamer": ["3fd9c808-103c-4e2d-9ae4-2b605faa2f4b", "37144825-cd50-4a47-babf-162898a769fd", "04fb1e69-66fa-42bb-b1e2-f248bcf4c99e", "89d161c4-9ff6-4c3a-8df3-dbd7d9772ff4"]
          },
          {
            "TestAgain": ["fb7ab00c-12df-4fc4-a2bf-4f3d6c50a31d"]
            }
      ]
      */

      let itemToAdd;
      if(category.useNameAsFilter) {
        itemToAdd = {[option]: option};
      } else {
        itemToAdd = {[option.name]: category.options.filter(i => i.name === option.name).map(i => i.id)}
      }

      let newlyUpdatedListOfFilters = updatedListOfFilters.map(fOption => 
        fOption.id === category.id
          ? {
              ...fOption,
              filterBy:
                // Check if the update is for dates
                category.type === filterFormats.DATE
                  ? valueIndex === 1 ? { ...fOption.filterBy, end: option } : { ...fOption.filterBy, start: option }
                  : // Else, update normal filter options
                  newCheckedValue
                    ? [...fOption.filterBy, itemToAdd] // Add the option
                    : fOption.filterBy.filter(item => !item.hasOwnProperty(category.useNameAsFilter ? option : option.name)) // Remove the option
            }
          : fOption
      );    
      setUpdatedListOfFilters(newlyUpdatedListOfFilters);
    };

    const classes = Styles();

    return (
        <Drawer
          anchor="right"
          open={open}
          onClose={() => toggleDrawer(false)}
        >
          <Box className={classes.root}>
            <Box className={classes.filterTitle}>
              <Typography variant="h6">Filters</Typography>
              <Button onClick={() => setOpen(false)} className={classes.filterClose}>
                <QuantifeelSvgIcon
                      component={CloseIcon}
                      viewBox="0 0 25 25"
                    />
              </Button>
            </Box>
  
            {/* Clear All and Apply Buttons */}
            <Box className={classes.headerButtonsContainer}>
              <ProgressButton variant={PROGRESS_BUTTON_VARIANTS.CANCEL} text={"Clear All"} onClick={() => handleClear()} className={classes.headerButtons} />
              <ProgressButton variant={PROGRESS_BUTTON_VARIANTS.ACTION} text={"Apply"} onClick={() => handleApply()} className={classes.headerButtons} />
            </Box>

            {/* Filter Accordions */}
            {updatedListOfFilters.map((filter, index) => (
              <Accordion key={index} className={classes.filterContainer} disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.filterSummary}>
                  <Typography>{filter.name}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.filterDetails}>
                    <FormGroup>
                        {handleRemovingDuplicates(filter).map((option, index) => {
                            if(filter.type === filterFormats.DATE) {
                              return (
                                <div key={index} className={classes.dateContainer}>
                                  <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DateTimePicker 
                                      label="Start Date"
                                      value={!!(filter.filterBy.start) ? moment(filter.filterBy.start) : null}
                                      onChange={(newValue) => handleFilterUpdate(filter, null, moment.parseZone(newValue.toString()).format(), 0)}
                                      disableFuture
                                      onAccept={(val) => handleFilterUpdate(filter, null, moment.parseZone(val.toString()).format(), 0)}
                                    />
                                    <DateTimePicker 
                                      label="End Date"
                                      value={!!(filter.filterBy.end) ? moment(filter.filterBy.end) : null}
                                      onChange={(newValue) => handleFilterUpdate(filter, null, moment.parseZone(newValue.toString()).format(), 1)}
                                      disableFuture
                                      onAccept={(val) => handleFilterUpdate(filter, null, moment.parseZone(val.toString()).format(), 1)}
                                    />
                                  </LocalizationProvider>
                                </div>
                              );
                            } 
                            return (   
                                <FormControlLabel 
                                    key={index}
                                    control={
                                        <Checkbox 
                                            checked={!!(filter.filterBy.find(i => Object.keys(i).find(key => key === (filter.useNameAsFilter ? option : option.name)))) ?? false} 
                                            onChange={(e) => handleFilterUpdate(filter, e.target.checked, option)} 
                                            name={option.name} 
                                        />} 
                                    label={filter.useNameAsFilter ? option : option.name} />
                            )
                        })}
                    </FormGroup>
                </AccordionDetails>
              </Accordion>
            ))}
  
          </Box>
        </Drawer>
    );
}

export default FilterDrawer;