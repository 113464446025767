import * as React from "react";
import {useContext, useEffect, useState, useMemo} from "react";
import {useQuery} from 'react-query';
import {constructFilterString, getCustomerAreas, getCustomerDroneModels, getCustomerLines, getCustomerMachineCenters, getCustomerPlants, getCustomerRunsQuery} from '../../query/queries'
import {Link, useHistory} from 'react-router-dom';
import {SiteContext} from "../../Context";
import {DATE_TIME_FORMAT, PERMISSION_METHOD_GET, PERMISSION_METHOD_UPDATE} from "../../shared/Utilities";
import queryString from "query-string";
import ProtectedMoment from "../../shared/components/ProtectedMoment/ProtectedMoment";
import EnhancedTable from "../../shared/components/EnhancedTable/EnhancedTable";
import '../PageStyles/TablePage.css'
import { handlePermissionRedirect } from "../../shared/Utilities";
import PageHeader from '../../shared/components/PageHeader/PageHeader'
import translations from '../../translations/en.json'
import { makeStyles } from '@mui/styles'
import { SST_PAGE_LIST_LINES, CONTROL_POINT_SECTION_STATES, filterFormats } from '../../Constants'
import { Tooltip, Typography } from "@mui/material";
import CopyLabel from "../../shared/components/CopyLabel/CopyLabel";
import { GREY_2 } from "../../shared/Theme/GreyScale";

const acceptablePagePermission = [
    {entity: 'Run', method: PERMISSION_METHOD_UPDATE, modifier: ''},
    {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
  ];

const COLUMN_IDS = {
    ID: "id",
    DATE_RECORDED: 'dateRecorded',
    CONTROL_POINTS: 'controlPoints',
    DRONE: 'drone',
    PLANT: 'plant',
    LINE: 'line',
    MACHINE_CENTER: 'machineCenter',
    AREA: 'area',
    ACTIONS: 'actions'
}

const columns = [
    {id: COLUMN_IDS.ID, label: translations.pages.listRuns.columnId, serverSideSortById: "virtualRunId", width: "70px"},
    {id: COLUMN_IDS.DATE_RECORDED, label: translations.pages.listRuns.columnDateRecorded, serverSideSortById: "dateRecorded", width: "175px"},
    {id: COLUMN_IDS.CONTROL_POINTS, label: translations.common.controlPoints.controlPoint, width: "220px", disableSort: true},
    {id: COLUMN_IDS.DRONE, label: translations.pages.listRuns.columnDrone, serverSideSortById: "droneModel", width: "100px"},
    {id: COLUMN_IDS.PLANT, label: translations.common.plants.plant, serverSideSortById: "plantName", width: "200px"},
    {id: COLUMN_IDS.LINE, label: translations.common.lines.line, serverSideSortById: "lineName", width: "200px"},
    {id: COLUMN_IDS.MACHINE_CENTER, label: translations.common.machineCenters.machineCenter, serverSideSortById: "machineCenterName", width: "200px"},
    {id: COLUMN_IDS.AREA, label: translations.common.areas.area, serverSideSortById: "areaId", width: "200px"},
    {id: COLUMN_IDS.ACTIONS, label: translations.common.actions, width: "110px", disableSort: true}
];

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    controlPointSectionContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1)
    },
    tooltipPopup: {
        maxWidth: 'none',
        backgroundColor: GREY_2
    },
    controlPointSectionContainerToolTip: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    controlPointSectionBox: {
        minWidth: '32px',
        height: '32px',
        borderRadius: '8px',
        border: '1px solid black'
    },
    textOverflowHiddenEllipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
}));

// Required filterAPI information (pagination)
const paginationApiInformation = {
    pageSize: 25, 
    page: 0,
    sortDirection: 'DESC',
    sortBy: 'id'
}

const ListRuns = (props) => {

    const history = useHistory();
    const {setBreadcrumbs, hasPermission, currentCustomer, complexFilters} = useContext(SiteContext);

    const [filteredRows, setFilteredRows] = useState([])
    const [filterOptions, setFilterOptions] = useState([])
    const [resetFilters, setResetFilters] = useState(true)
    const [paginationInformation, setPaginationInformation] = useState(paginationApiInformation)
    const [activeTooltip, setActiveTooltip] = useState({ rowId: null, cell: null });

    const filterAccessKey = "tableQualifierResults"

    const {isLoading: isLoadingPlants, data: plants=[]} = useQuery(['plants', {customerId: currentCustomer}], getCustomerPlants, {enabled: !!currentCustomer});
    const {isLoading: isLoadingLines, data: lines=[]} = useQuery(['lines', {customerId: currentCustomer}], getCustomerLines, {enabled: !!currentCustomer});
    const {isLoading: isLoadingMachineCenters, data: machineCenters=[]} = useQuery(['machineCenters', {customerId: currentCustomer}], getCustomerMachineCenters, {enabled: !!currentCustomer});
    const {isLoading: isLoadingAreas, data: areas=[]} = useQuery(['areas', {customerId: currentCustomer}], getCustomerAreas, {enabled: !!currentCustomer});
    const {isLoading: isLoadingDroneModels, data: droneModels=[]} = useQuery(['droneModels', {customerId: currentCustomer}], getCustomerDroneModels, {enabled: !!currentCustomer});
    const {isLoading: isLoadingQueryRuns, data: queryRuns} = useQuery(['queryRuns', 
        {customerId: currentCustomer},
        {sortBy: paginationInformation.sortBy},
        {sortOrder: paginationInformation.sortDirection},
        {pageSize: paginationInformation.pageSize},
        {page: paginationInformation.page},
        {filter: constructFilterString(complexFilters[filterAccessKey])},
        {includeControlChartData: true}
    ], getCustomerRunsQuery, {enabled: !!currentCustomer});

    // Used for the filters. Doesn't require all data.
    const isFiltersLoading = useMemo(() => {
        return isLoadingPlants ||
                isLoadingLines ||
                isLoadingDroneModels ||
                isLoadingAreas ||
                isLoadingMachineCenters;
      }, [
        isLoadingPlants,
        isLoadingLines,
        isLoadingDroneModels,
        isLoadingAreas,
        isLoadingMachineCenters    
    ]);

    // Used specifically for the table. Requires all data.
    const isRowsLoading = useMemo(() => {
        return isLoadingPlants ||
                isLoadingLines ||
                isLoadingMachineCenters ||
                isLoadingDroneModels ||
                isLoadingAreas ||
                isLoadingQueryRuns;
      }, [
        isLoadingPlants,
        isLoadingLines,
        isLoadingMachineCenters,
        isLoadingDroneModels,
        isLoadingAreas,
        isLoadingQueryRuns
    ]);

    const rows = useMemo(() => {
        if(!!(queryRuns)) {
            return queryRuns.data
        }
    }, [queryRuns])

    // -------------------
    // -- BEGIN useEffects --
    // -------------------

    useEffect(() => {
        document.title = translations.pages.listRuns.title;

        setBreadcrumbs([
            {
                title: translations.pages.listRuns.title,
            }
        ]);
    }, [setBreadcrumbs, props.location.search]);

    
    useEffect(() => {
        if(!isFiltersLoading) {
            /**
             * Initialize the filters for this table
             * We need information from APIs before we create the list of filters (plants/lines/machinecenters)
             * 
             * Note: useNameAsFilter is used when we don't want to store the id of the object in the filter. Is this is used, the options for the filter need to be strings.
             * Ex. When we pass the droneModel to the getCustomerQueryRuns call, we pass it as droneModel in 81
             * instead of droneModel in {DRONE_ID}
             */
            let initialFilterOptions = [
                { id: 0, name: translations.common.plants.plant, type: filterFormats.CHECKBOX, options: plants, filterBy: [] },
                { id: 1, name: translations.common.lines.line, type: filterFormats.CHECKBOX, options: lines, filterBy: [] },
                { id: 2, name: translations.common.machineCenters.machineCenter, type: filterFormats.CHECKBOX, options: machineCenters, filterBy: [] },
                { id: 3, name: translations.common.areas.area, type: filterFormats.CHECKBOX, options: areas, filterBy: [] },
                { id: 4, name: translations.pages.listRuns.filterDate, type: filterFormats.DATE, options: ['date'], filterBy: {start: null, end: null}, useNameAsFilter: true },
                { id: 5, name: translations.pages.listRuns.filterDrone, type: filterFormats.CHECKBOX, options: droneModels, filterBy: [], useNameAsFilter: true }
            ];
    
            const qs = queryString.parse(props.location.search);

            const plantItem = initialFilterOptions.find(i => i.id === 0);
            const lineItem = initialFilterOptions.find(i => i.id === 1);
            const machineCenterItem = initialFilterOptions.find(i => i.id === 2);

            let isFilterResetting = false;

            // Check the function handleFilterUpdate in FilterDrawer.js for explanation for the formatting for the following values.
            // If the line id is one of the params, add that filter (and all parent filters)
            if(qs.lineId) {
                let line = lines.find(line => line.id === qs.lineId);
                isFilterResetting = true;
                
                if(!line) {
                    // Add a invalid property so we can show the error message in the pill container
                    lineItem.filterBy.push({invalid: qs.lineId});
                } else {
                    let plant = plants.find(plant => plant.id === line?.plantId);
                    plantItem.filterBy.push({[plant.name]: line.plantId});
                    lineItem.filterBy.push({[line.name]: line.id});
                }
            }

            // If the machine center id is one of the params, add that filter (and all parent filters)
            if(qs.machineCenterId) { 
                let machineCenter = machineCenters.find(mc => mc.id === qs.machineCenterId);
                isFilterResetting = true
                
                if(!machineCenter) {
                    // Add a invalid property so we can show the error message in the pill container
                    machineCenterItem.filterBy.push({invalid: qs.machineCenterId});
                } else {
                    let line = lines.find(line => line.id === machineCenter?.lineId);
                    let plant = plants.find(plant => plant.id === machineCenter?.plantId);
                    // Requires checks to see if the filter has already been added since both a lineId and machineCenterId can be in the queryParams
                    if(!plantItem.filterBy.find(pFilter => pFilter[plant.name] === plant.id)) {
                        plantItem.filterBy.push({[plant.name]: machineCenter.plantId});
                    }
    
                    if(!lineItem.filterBy.find(lFilter => lFilter[line.name] === line.id)) {
                        lineItem.filterBy.push({[line.name]: machineCenter.lineId});
                    }
    
                    machineCenterItem.filterBy.push({[machineCenter.name]: machineCenter.id});
                }
            }

            // If the control point id is one of the params, add that filter
            if(qs.controlPointId) {
                // Not implemented yet as we currently don't know what control point property to filter by 
            }

            setResetFilters(isFilterResetting)
            setFilterOptions(initialFilterOptions);       
        } 
    }, [isFiltersLoading]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Reset pagination when a filter is applied
        // Similar to when we change the rowsPerPage prop in the EnhancedTable
        setPaginationInformation((pagination) => ({...pagination, page: 0}))
    }, [complexFilters[filterAccessKey]]);

    useEffect(() => {
        // Set the filtered rows that will be shown in the enhanced table
        if(!!(rows)) {
            // Forms an array of droneModel objects (id, name) then filter out the duplicates
            // This works better for the filter drawer
            setFilteredRows(generateRows(rows.content, activeTooltip));
        }
    }, [rows, isRowsLoading, activeTooltip]) // eslint-disable-line react-hooks/exhaustive-deps

    // -------------------
    // -- BEGIN helper functions --
    // -------------------

    const generateRows = (rows) => {
        return rows.map(run => {
            const line = lines.find((x) => x.id === run.lineId);
            const machineCenter = machineCenters.find((x) => x.id === run.machineCenterId);
            const plant = plants.find((x) => x.id === line?.plantId);
            const area = areas.find((x) => x.id === run.areaId);
            return {
                id: run.virtualRunId,
                dateRecorded: <ProtectedMoment date={run.dateRecorded} format={DATE_TIME_FORMAT + ' z'} rawval={run.dateRecorded}/>,
                controlPoints: createControlPointSections(run, activeTooltip),
                drone: <CopyLabel value={run.droneId} rawval={run.droneModel?.toLowerCase()}><Typography className={classes.textOverflowHiddenEllipsis}>{run.droneModel}</Typography></CopyLabel>,
                plant: plant ? <CopyLabel value={plant.id} rawval={plant.name?.toLowerCase()}><Typography className={classes.textOverflowHiddenEllipsis}>{plant.name}</Typography></CopyLabel> : "",
                line: line ? <CopyLabel value={line.id} rawval={line.name?.toLowerCase()}><Typography className={classes.textOverflowHiddenEllipsis}>{line.name}</Typography></CopyLabel> : "",
                machineCenter: machineCenter ? <CopyLabel value={machineCenter.id} rawval={machineCenter.name?.toLowerCase()}><Typography className={classes.textOverflowHiddenEllipsis}>{machineCenter.name}</Typography></CopyLabel> : "",
                area: area ? <CopyLabel value={area.id} rawval={area.name?.toLowerCase()}><Typography className={classes.textOverflowHiddenEllipsis}>{area.name}</Typography></CopyLabel> : "",
                actions: <Link to={"/view-run?id=" + run.id}>Run Details</Link>
            }
        })
    }

    const onBack = () => {
        // If there is a page in history, go back, else, go to /list-lines...
        return !!(history.location.key) ? history.goBack() : history.push(`/${SST_PAGE_LIST_LINES}`)
    }

    // -------------------
    // -- BEGIN renders --
    // -------------------

    const classes = useStyles();

    const renderHeader = () => {
        return (
          <PageHeader
            onBack={onBack}
            pageTitle={translations.pages.listRuns.title}
          />
        )
    }

    const createControlPointSections = (run, activeTooltip) => {
        if(!(run.controlChartStats) || run.controlChartStats.length === 0) {
            return;
        }

        let controlPoints = run.controlChartStats.map((controlPoint, index) => { 
            let cpColor = "";
            let isInControl = controlPoint.specLimitStatus === CONTROL_POINT_SECTION_STATES.IN_CONTROL.key && controlPoint.controlLimitStatus === CONTROL_POINT_SECTION_STATES.IN_CONTROL.key
            let isOutOfControl = controlPoint.specLimitStatus === CONTROL_POINT_SECTION_STATES.OUT_OF_CONTROL.key || controlPoint.controlLimitStatus === CONTROL_POINT_SECTION_STATES.OUT_OF_CONTROL.key
            let controlPointDoesNotHaveEnoughData = controlPoint.specLimitStatus === CONTROL_POINT_SECTION_STATES.INSUFFICIENT_DATA.key && controlPoint.controlLimitStatus === CONTROL_POINT_SECTION_STATES.INSUFFICIENT_DATA.key

            if(isInControl) {
                cpColor = CONTROL_POINT_SECTION_STATES.IN_CONTROL.color;
            } else if(isOutOfControl) {
                cpColor = CONTROL_POINT_SECTION_STATES.OUT_OF_CONTROL.color;
            } else if(controlPointDoesNotHaveEnoughData) {
                cpColor = CONTROL_POINT_SECTION_STATES.INSUFFICIENT_DATA.color;
            } else {
                cpColor = CONTROL_POINT_SECTION_STATES.DIDNT_SEE_CONTROL_POINT.color;
            }

            return <div key={index} className={classes.controlPointSectionBox} style={{backgroundColor: cpColor}} />
        })

        let activeTooltipFocus = 'controlPoint';

        return (
            <Tooltip
                open={activeTooltip.rowId === run.id && activeTooltip.cell === activeTooltipFocus && controlPoints.length > 5}
                placement="top"
                arrow
                onClick={() => setActiveTooltip({ rowId: run.id, cell: activeTooltipFocus })}
                onMouseLeave={() => setActiveTooltip({ rowId: null, cell: null })}
                title={showControlPointTooltip(controlPoints)}
                classes={{
                    tooltip: classes.tooltipPopup
                }}
            >
                <div className={classes.controlPointSectionContainer} style={{cursor: controlPoints.length > 5 ? 'pointer' : null}}>
                    {controlPoints.slice(0, 5)}
                    {controlPoints.length > 5 && <Typography sx={{fontSize: '18px'}}>...</Typography>}
                </div>
            </Tooltip>
        )
    }

    const showControlPointTooltip = (controlPoints) => {
        return (
            <div className={classes.controlPointSectionContainerToolTip}>
                {controlPoints}
            </div>
        )
    }

    return (
      <div className={classes.root}>

        {renderHeader()}

        <div className="page" data-testid="list-runs-page">
            {handlePermissionRedirect(translations.pages.listRuns.title, history, hasPermission, acceptablePagePermission) &&
                <div className="container">
                    <EnhancedTable
                        isLoading={isRowsLoading}
                        order={'desc'}
                        orderBy={COLUMN_IDS.DATE_RECORDED}
                        rows={filteredRows}
                        headCells={columns}
                        setPaginationValues={setPaginationInformation}
                        totalNumberOfItems={!!rows && rows.totalElements}
                        enableFilter
                        filterAccessKey={filterAccessKey}
                        filterOptions={filterOptions}
                        resetFilters={resetFilters}
                        enableServerSideSorting
                        rowsPerPage={paginationInformation.pageSize}
                    />
                </div>
            }
        </div>

      </div>
    );
};

export default ListRuns;
