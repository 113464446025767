import { makeStyles } from '@mui/styles'

const Styles = makeStyles((theme) => ({
    root: {
        width: '400px'
    },
    dateContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12
    },
    headerButtonsContainer: {
        display: 'flex',
        gap: 12,
        justifyContent: 'spaceBetween',
        margin: 12
    },
    headerButtons: {
        flex: 1,
    },
    filterTitle: {
        height: '50px',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 12,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    filterClose: {
        position: 'absolute',
        right: 0
    },
    filterContainer: {
        margin: "0 12px 0 12px",
        '&:after': {
            margin: "0 12px 0 12px",
        }
    },
    filterSummary: {
        borderBottom: `1px solid ${theme.palette.primary.main}`
    },
    filterDetails: {
        maxHeight: '300px', 
        overflowY: 'auto'
    }
}));

export default Styles;