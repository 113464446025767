import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker, LocalizationProvider } from '@mui/lab'
import {
  Box,
  Collapse,
  Grid,
  MenuItem,
  Paper,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import {
  getAggregatedSensorData,
  getCustomerLines,
  getCustomerPlants,
  getSensorsByCustomer,
} from '@Queries'
import moment from 'react-moment'
import { useSnackbar } from 'notistack'
import queryString from 'query-string'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { SiteContext } from '../../Context'
import { ReactComponent as BackIcon } from '../../img/icons/Back button.svg'
import EnhancedTable from '../../shared/components/EnhancedTable/EnhancedTable'
import QuicksightChart from '../../shared/components/QuicksightChart/QuicksightChart'
import SSTDropdown from '../../shared/components/SSTDropdown'
import { G, INCHES_PER_SECOND } from '../../shared/MultiSensorGraphsUtilities'
import {
  ACCELERATION,
  ACTIVE,
  activeInactiveValues,
  aggregatedByValues,
  aggregationMethodValues,
  AIR_CHIP_TEMPERATURE,
  AIR_PARAMETERS,
  ALL,
  allDefaults,
  amplitudeValues,
  CELSIUS,
  convertQuicksightChartData,
  convertQuicksightTemperatureData,
  DAY,
  determineAggregatedByValues,
  DO_NOT_SHOW_ERROR_VALUES,
  ENDDATE_LABEL,
  errorValues,
  ERROR_VALUES_THRESHOLD,
  FAHRENHEIT,
  fftBandValues,
  filterLines,
  generateRows,
  getListOfSensorIds,
  handleUnitCategoryChange,
  handleUnitChange,
  K,
  METRIC,
  MULTISENSOR,
  multiSensorFilterValues,
  MULTISENSORID_OBJECT_PROPERTY,
  PERCENT_RH,
  QUICKSIGHT_CHART_DATE_FORMAT,
  READING,
  STARTDATE_LABEL,
  unitValues,
  validRefetchCondition,
  VIBRATION,
  X_AXIS_BAND_ONE_TO_SEVEN,
  Y_AXIS_BAND_ONE_TO_SEVEN,
  Z_AXIS_BAND_ONE_TO_SEVEN,
} from '../../shared/QuicksightUtilities'
import ConditionTrendDetailsStyles from './Styles/ConditionTrendDetailsStyles'

const xAxisBandLabels = [
  'X0-27',
  'X27-57',
  'X57-117',
  'X117-327',
  'X327-627',
  'X627-1000',
  'X Overall(Hz)',
]
const yAxisBandLabels = [
  'Y0-27',
  'Y27-57',
  'Y57-117',
  'Y117-327',
  'Y327-627',
  'Y627-1000',
  'Y Overall(Hz)',
]
const zAxisBandLabels = [
  'Z0-27',
  'Z27-57',
  'Z57-117',
  'Z117-327',
  'Z327-627',
  'Z627-1000',
  'Z Overall(Hz)',
]

const tableColumns = [
  { id: 'id', label: 'Id', hidden: true },
  { id: 'multisensor', label: 'Multisensor' },
  { id: 'active', label: '' },
  { id: 'lineName', label: 'Line' },
  { id: 'plantName', label: 'Plant' },
  { id: 'customer', label: 'Customer' },
  { id: 'lastReading', label: 'Last Reading' },
]

const atmosphericParameterProperties = [
  {
    id: 1,
    label: 'Air Humidity',
    queryLabel: 'airHumidity',
    isChecked: false,
    unit: PERCENT_RH,
  },
  {
    id: 2,
    label: 'Air Pressure',
    queryLabel: 'airPressure',
    isChecked: false,
    unit: INCHES_PER_SECOND,
  },
  { id: 3, label: 'Co2', queryLabel: 'gasEco2', isChecked: false, unit: K },
  {
    id: 4,
    label: 'Indoor Air Quality',
    queryLabel: 'gasIaq',
    isChecked: false,
    unit: '',
  },
  {
    id: 5,
    label: 'Light Intensity',
    queryLabel: 'light',
    isChecked: false,
    unit: K,
  },
  { id: 6, label: 'TVOC', queryLabel: 'gasTvoc', isChecked: false, unit: '' },
]

const airChipProperties = [
  { id: 1, label: 'Air Temperature', queryLabel: 'airTemperature' },
  { id: 2, label: 'Chip Temperature', queryLabel: 'chipTemperature' },
]

const ConditionTrendDetails = (props) => {
  const history = useHistory()
  const location = useLocation()

  const plantId = queryString.parse(location.search)?.plantId
  const lineId = queryString.parse(location.search)?.lineId

  const { setBreadcrumbs, userDetails, currentCustomer } =
    useContext(SiteContext)

  const [isControlsOpen, setIsControlsOpen] = useState(true)
  const [showErrorValues, setShowErrorValues] = useState(false)
  const [isMetric, setIsMetric] = useState(true)
  const [chartUnit, setChartUnit] = useState('g')
  const [FFTXData, setFFTXData] = useState([])
  const [FFTYData, setFFTYData] = useState([])
  const [FFTZData, setFFTZData] = useState([])
  const [convertedFFTXData, setConvertedFFTXData] = useState([])
  const [convertedFFTYData, setConvertedFFTYData] = useState([])
  const [convertedFFTZData, setConvertedFFTZData] = useState([])
  const [airParameterData, setAirParameterData] = useState([])
  const [airChipTemperatureData, setAirChipTemperatureData] = useState([])
  const [convertedAirChipTemperatureData, setConvertedAirChipTemperatureData] =
    useState([])
  const [chartDates, setChartDates] = useState([])
  const [inputBandData, setInputBandData] = useState([])
  const [startDate, setStartDate] = useState(
    !!location.state?.startDate
      ? moment(location.state.startDate).toISOString()
      : moment().subtract(7, DAY.label).toISOString()
  )
  const [endDate, setEndDate] = useState(
    !!location.state?.endDate
      ? moment(location.state.endDate).toISOString()
      : moment().toISOString()
  )
  const [aggregatedBy, setAggregatedBy] = useState(DAY.label)
  const [plant, setPlant] = useState(allDefaults)
  const [line, setLine] = useState(allDefaults)
  const [multiSensorNameFilter, setMultiSensorNameFilter] = useState(ALL)
  const [multiSensorActiveStatus, setMultiSensorActiveStatus] = useState(ALL)
  const [aggregationMethod, setAggregationMethod] = useState({
    key: 'max',
    value: 'Maximum',
  })
  const [amplitude, setAmplitude] = useState(ACCELERATION)
  const [measurementSystem, setMeasurementSystem] = useState(METRIC)
  const [errorValue, setErrorValues] = useState(DO_NOT_SHOW_ERROR_VALUES)
  const [selectedAtmosphericParameter, setSelectedAtmosphericParameter] =
    useState(atmosphericParameterProperties[0].label)
  const [FFTBand, setFFTBand] = useState('')
  const [FFTBandField, setFFTBandField] = useState('')
  const [aggregatedBySelections, setAggregatedBySelections] =
    useState(aggregatedByValues)
  const [selectedTableRow, setSelectedTableRow] = useState(undefined)
  const [sensorList, setSensorList] = useState([])
  const [plants, setPlants] = useState([allDefaults])
  const [lines, setLines] = useState([allDefaults])

  const { enqueueSnackbar } = useSnackbar()

  const filterTable = useCallback(() => {
    return sensorList?.filter((sensor) => {
      let plantCondition =
        plant.name !== allDefaults.name ? sensor.plantName === plant.name : true
      let lineCondition =
        line.name !== allDefaults.name ? sensor.lineName === line.name : true
      let multiSensorCondition =
        multiSensorNameFilter !== ALL
          ? sensor.name.includes(multiSensorNameFilter)
          : true
      let activeInactiveCondition =
        multiSensorActiveStatus !== ALL
          ? multiSensorActiveStatus === ACTIVE
            ? moment(
                !!sensor.dateLastSeenRecord
                  ? sensor.dateLastSeenRecord
                  : sensor.dateLastUpdated
              ).isAfter(moment().subtract(7, DAY.label))
            : moment(
                !!sensor.dateLastSeenRecord
                  ? sensor.dateLastSeenRecord
                  : sensor.dateLastUpdated
              ).isBefore(moment().subtract(7, DAY.label))
          : true
      let FFTBandCondition =
        FFTBandField !== '' && FFTBand !== ''
          ? inputBandData.includes(sensor.id)
          : true
      return (
        plantCondition &&
        lineCondition &&
        multiSensorCondition &&
        activeInactiveCondition &&
        FFTBandCondition
      )
    })
  }, [
    FFTBand,
    FFTBandField,
    inputBandData,
    line,
    multiSensorActiveStatus,
    multiSensorNameFilter,
    plant,
    sensorList,
  ])

  const { isLoading: isLoadingPlants, data: plantsResponse = [] } = useQuery(
    ['plants', { customerId: currentCustomer }],
    getCustomerPlants
  )
  const { isLoading: isLoadingLines, data: linesResponse = [] } = useQuery(
    ['lines', { customerId: currentCustomer }],
    getCustomerLines,
    { enabled: plants.length > 0 }
  )

  const { isLoading: isLoadingSensors, data: sensors = [] } = useQuery(
    ['sensors', { customerId: currentCustomer }, { detailed: true }],
    getSensorsByCustomer
  )

  const {
    isLoading: isAggregatedVibrationsLoading,
    data: aggregatedVibrations,
  } = useQuery(
    [
      'sensorAggregationVibrations',
      { customerId: currentCustomer },
      {
        sensorIds: !!selectedTableRow?.id
          ? selectedTableRow.id
          : getListOfSensorIds(filterTable()),
        startDate: startDate,
        endDate: endDate,
        readingType: VIBRATION,
        resolution: aggregatedBy,
        aggregationFunction: aggregationMethod.key,
      },
    ],
    getAggregatedSensorData,
    {
      enabled: validRefetchCondition(
        startDate,
        endDate,
        selectedTableRow,
        aggregatedBy
      ),
    }
  )

  const { isLoading: isAggregatedReadingsLoading, data: aggregatedReadings } =
    useQuery(
      [
        'sensorAggregationReadings',
        { customerId: currentCustomer },
        {
          sensorIds: !!selectedTableRow?.id
            ? selectedTableRow.id
            : getListOfSensorIds(filterTable()),
          startDate: startDate,
          endDate: endDate,
          readingType: READING,
          resolution: aggregatedBy,
          aggregationFunction: aggregationMethod.key,
        },
      ],
      getAggregatedSensorData,
      {
        enabled: validRefetchCondition(
          startDate,
          endDate,
          selectedTableRow,
          aggregatedBy
        ),
        onError: (error) => {
          if (error?.response?.data?.message) {
            enqueueSnackbar(error.response.data.message, {
              variant: 'error',
            })
          }
        },
      }
    )

  const clearStates = () => {
    setChartDates([])
    setAirParameterData([])
    setAirChipTemperatureData([])
    setFFTXData([])
    setFFTYData([])
    setFFTZData([])
    setInputBandData([])
    clearConvertedStates()
  }

  const clearConvertedStates = () => {
    setConvertedFFTXData([])
    setConvertedFFTYData([])
    setConvertedFFTZData([])
    setConvertedAirChipTemperatureData([])
  }

  const classes = ConditionTrendDetailsStyles()

  const controlDropDowns = [
    {
      id: 1,
      label: 'Aggregated By',
      value: aggregatedBy,
      action: (e) => setAggregatedBy(e.target.value),
      list: aggregatedBySelections.map((aggregatedByObj) => (
        <MenuItem key={aggregatedByObj.id} value={aggregatedByObj.value}>
          {aggregatedByObj.value}
        </MenuItem>
      )),
    },
    {
      id: 2,
      label: 'Plant',
      value: plant.name,
      action: (e) => {
        setPlant(plants.find((plant) => plant.name === e.target.value))
        setLine(allDefaults)
      },
      list: plants.map((plantObj) => {
        return (
          <MenuItem key={plantObj.id} value={plantObj.name}>
            {plantObj.name}
          </MenuItem>
        )
      }),
    },
    {
      id: 3,
      label: 'Line',
      value: line.name,
      action: (e) => {
        setLine(lines.find((line) => line.name === e.target.value))
      },
      list: filterLines(lines, plant).map((lineObj) => {
        return (
          <MenuItem key={lineObj.id} value={lineObj.name}>
            {lineObj.name}
          </MenuItem>
        )
      }),
    },
    {
      id: 4,
      label: 'Multisensor Filter',
      value: multiSensorNameFilter,
      action: (e) => setMultiSensorNameFilter(e.target.value),
      list: multiSensorFilterValues.map((sensorFilterObj) => (
        <MenuItem key={sensorFilterObj.id} value={sensorFilterObj.value}>
          {sensorFilterObj.value}
        </MenuItem>
      )),
    },
    {
      id: 5,
      label: 'Active/Inactive Sensors',
      value: multiSensorActiveStatus,
      action: (e) => setMultiSensorActiveStatus(e.target.value),
      list: activeInactiveValues.map((activeStatusObj) => (
        <MenuItem key={activeStatusObj.id} value={activeStatusObj.value}>
          {activeStatusObj.value}
        </MenuItem>
      )),
    },
    {
      id: 6,
      label: 'Aggregation Method',
      value: aggregationMethod.value,
      action: (e) =>
        setAggregationMethod({
          key:
            e.target.value === 'Average'
              ? 'Avg'
              : e.target.value.substring(0, 3),
          value: e.target.value,
        }),
      list: aggregationMethodValues.map((aggregationObj) => (
        <MenuItem key={aggregationObj.id} value={aggregationObj.value}>
          {aggregationObj.value}
        </MenuItem>
      )),
    },
    {
      id: 7,
      label: 'Amplitude',
      value: amplitude,
      action: (e) => setAmplitude(e.target.value),
      list: amplitudeValues.map((amplitudeObj) => (
        <MenuItem key={amplitudeObj.id} value={amplitudeObj.value}>
          {amplitudeObj.value}
        </MenuItem>
      )),
    },
    {
      id: 8,
      label: 'Units',
      value: measurementSystem,
      action: (e) =>
        handleUnitCategoryChange(
          e.target.value,
          setMeasurementSystem,
          setIsMetric
        ),
      list: unitValues.map((unitObj) => (
        <MenuItem key={unitObj.id} value={unitObj.value}>
          {unitObj.value}
        </MenuItem>
      )),
    },
    {
      id: 9,
      label: 'Error Values',
      value: errorValue,
      action: (e) => {
        setErrorValues(e.target.value)
        setShowErrorValues(!showErrorValues)
      },
      list: errorValues.map((errorObj) => (
        <MenuItem key={errorObj.id} value={errorObj.value}>
          {errorObj.value}
        </MenuItem>
      )),
    },
    {
      id: 10,
      label: 'Air Parameters',
      value: selectedAtmosphericParameter,
      action: (e) => {
        setSelectedAtmosphericParameter(e.target.value)
      },
      list: atmosphericParameterProperties.map((param) => (
        <MenuItem key={param.id} value={param.label}>
          {param.label}
        </MenuItem>
      )),
    },
  ]

  const handleRowOnClick = (_, key) => {
    let selectedRowIndex = sensorList.findIndex((sensor) => sensor.id === key)
    if (selectedTableRow?.id !== sensorList[selectedRowIndex].id) {
      setSelectedTableRow({
        id: sensorList[selectedRowIndex].id,
        name: sensorList[selectedRowIndex].name,
        dateLastUpdated: sensorList[selectedRowIndex].dateLastUpdated,
      })
    }
  }

  const handleBandData = (
    reading,
    FFTBandNumber,
    data,
    axis,
    errorValuesThreshold
  ) => {
    filterBandData(reading, data, errorValuesThreshold, axis, FFTBandNumber)
    filterBandFieldSensors(reading, FFTBandNumber)
  }

  const filterBandData = (
    reading,
    data,
    errorThreshold,
    axis,
    FFTBandNumber
  ) => {
    if (!showErrorValues) {
      if (reading['fft' + axis + (FFTBandNumber + 1)] < errorThreshold) {
        data.push(reading['fft' + axis + (FFTBandNumber + 1)])
      }
    } else if (showErrorValues) {
      data.push(reading['fft' + axis + (FFTBandNumber + 1)])
    }
  }

  const filterBandFieldSensors = (reading, FFTBandNumber) => {
    setInputBandData((prev) => [
      ...prev,
      reading[MULTISENSORID_OBJECT_PROPERTY],
    ])
  }

  useEffect(() => {
    document.title = 'Condition Trend Details'
    setBreadcrumbs([{ title: 'Condition Trend Details' }])
  }, [setBreadcrumbs])

  useEffect(() => {
    if (!isLoadingPlants && plantsResponse.length > 0 && plants.length === 1) {
      setPlants([...plants, ...plantsResponse])
    }
  }, [plantsResponse, plants, isLoadingPlants])

  useEffect(() => {
    if (!isLoadingLines && linesResponse.length > 0 && lines.length === 1) {
      setLines([...lines, ...linesResponse])
    }
  }, [linesResponse, lines, isLoadingLines])

  useEffect(() => {
    let initialPlant =
      !isLoadingPlants && plantsResponse.find((plant) => plant.id === plantId)
    let initialLine =
      !isLoadingLines && linesResponse.find((line) => line.id === lineId)

    if (initialPlant) {
      setPlant(initialPlant)
    } else {
      setPlant(allDefaults)
    }

    if (initialLine) {
      setLine(initialLine)
    } else {
      setLine(allDefaults)
    }

    setSensorList(sensors)
  }, [
    sensors,
    history.location.state,
    lineId,
    plantId,
    lines,
    linesResponse,
    plantsResponse,
    isLoadingLines,
    isLoadingPlants,
  ])

  useEffect(() => {
    clearStates()
    let numberOfBands = 7
    let maximumNumberOfAirParameters = 2

    determineAggregatedByValues(
      startDate,
      endDate,
      setAggregatedBySelections,
      setAggregatedBy,
      aggregatedBySelections,
      aggregatedBy
    )

    aggregatedReadings?.sort(
      (a, b) =>
        moment(a.dateRecorded).valueOf() - moment(b.dateRecorded).valueOf()
    )
    aggregatedVibrations?.sort(
      (a, b) =>
        moment(a.dateRecorded).valueOf() - moment(b.dateRecorded).valueOf()
    )

    for (
      let FFTBandNumber = 0;
      FFTBandNumber < numberOfBands;
      FFTBandNumber++
    ) {
      let xData = []
      let yData = []
      let zData = []
      aggregatedVibrations?.forEach((reading) => {
        handleBandData(
          reading,
          FFTBandNumber,
          xData,
          'X',
          ERROR_VALUES_THRESHOLD
        )
        handleBandData(
          reading,
          FFTBandNumber,
          yData,
          'Y',
          ERROR_VALUES_THRESHOLD
        )
        handleBandData(
          reading,
          FFTBandNumber,
          zData,
          'Z',
          ERROR_VALUES_THRESHOLD
        )
      })
      setFFTXData((prev) => [...prev, xData])
      setFFTYData((prev) => [...prev, yData])
      setFFTZData((prev) => [...prev, zData])
      if (!!aggregatedVibrations) {
        setChartDates([
          ...new Set(
            aggregatedVibrations.map((reading) => {
              return moment(reading.dateRecorded).format(
                QUICKSIGHT_CHART_DATE_FORMAT
              )
            })
          ),
        ])
      }
    }

    for (
      let AirParamIndex = 0;
      AirParamIndex < maximumNumberOfAirParameters;
      AirParamIndex++
    ) {
      let airParamData = []
      let airChipData = []
      aggregatedReadings?.forEach((reading) => {
        let objectIndex = atmosphericParameterProperties.findIndex((obj) => {
          return obj.label === selectedAtmosphericParameter
        })
        airChipData.push(reading[airChipProperties[AirParamIndex].queryLabel])
        airParamData.push(
          reading[atmosphericParameterProperties[objectIndex]?.queryLabel]
        )
      })
      setAirParameterData((prev) => [...prev, airParamData])
      setAirChipTemperatureData((prev) => [...prev, airChipData])
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startDate,
    endDate,
    aggregatedReadings,
    aggregatedVibrations,
    showErrorValues,
    FFTBandField,
    selectedTableRow,
    selectedAtmosphericParameter,
  ])

  useEffect(() => {
    clearConvertedStates()
    handleUnitChange(amplitude, isMetric, setChartUnit)
    if (!isMetric) {
      convertQuicksightTemperatureData(
        airChipTemperatureData,
        setConvertedAirChipTemperatureData
      )
    }
  }, [amplitude, isMetric, airChipTemperatureData])

  useEffect(() => {
    if (chartUnit !== G) {
      convertQuicksightChartData(FFTXData, chartUnit, setConvertedFFTXData)
      convertQuicksightChartData(FFTYData, chartUnit, setConvertedFFTYData)
      convertQuicksightChartData(FFTZData, chartUnit, setConvertedFFTZData)
    }
  }, [chartUnit, FFTXData, FFTYData, FFTZData])

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div
          id="header"
          className={classes.titleHeader}
          onClick={() => setIsControlsOpen((isControlsOpen) => !isControlsOpen)}
        >
          <div className={classes.backButtonContainer}>
            <span onClick={() => history.push('/dashboard')}>
              <SvgIcon
                component={BackIcon}
                viewBox="8 8 32 32"
                className={classes.backButton}
              />
            </span>
            <span className={classes.divider} />
            <Typography variant="h6" style={{ whiteSpace: 'nowrap' }}>
              Condition Trend Details
            </Typography>
          </div>
          <Typography variant="h6">Controls</Typography>
        </div>
        <Collapse
          in={isControlsOpen}
          sx={{ borderBottom: '1px solid lightgrey' }}
        >
          <Grid container spacing={3} sx={{ padding: '12px' }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid item sx={{ width: 'fit-content' }}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label={STARTDATE_LABEL}
                  value={startDate}
                  minDateTime={moment().subtract(10, 'year')._d}
                  maxDateTime={moment().add(10, 'year')._d}
                  InputProps={{ className: classes.dropDown }}
                  onChange={(newDate) => {
                    setStartDate(newDate)
                  }}
                />
              </Grid>
              <Grid item sx={{ width: 'fit-content' }}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label={ENDDATE_LABEL}
                  value={endDate}
                  minDateTime={moment().subtract(10, 'year')._d}
                  maxDateTime={moment().add(10, 'year')._d}
                  InputProps={{ className: classes.dropDown }}
                  onChange={(newDate) => {
                    setEndDate(newDate)
                  }}
                />
              </Grid>
            </LocalizationProvider>
            {controlDropDowns.map((control) => (
              <Grid item key={control.id} sx={{ width: 'fit-content' }}>
                <SSTDropdown
                  classes={classes}
                  disabled={false}
                  label={control.label}
                  isLoadingContents={isLoadingSensors}
                  selectedValueId={control.value}
                  setValueFunc={control.action}
                  required={false}
                  fullWidth={false}
                  mappedList={control.list}
                />
                {''}
              </Grid>
            ))}
            <Grid item sx={{ width: 'fit-content' }}>
              <div className={classes.bandFieldContainer}>
                <SSTDropdown
                  classes={classes}
                  disabled={false}
                  label={'Band #'}
                  selectedValueId={FFTBand === 'None' ? '' : FFTBand}
                  setValueFunc={(e) => setFFTBand(e.target.value)}
                  required={false}
                  fullWidth={false}
                  mappedList={fftBandValues.map((fftBandObj) => (
                    <MenuItem key={fftBandObj.id} value={fftBandObj.value}>
                      {fftBandObj.value}
                    </MenuItem>
                  ))}
                />
                <TextField
                  value={FFTBandField}
                  size={'small'}
                  placeholder={'Band value'}
                  onChange={(e) => setFFTBandField(e.target.value)}
                  type={'number'}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.bandInput,
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Collapse>
        <Box sx={{ padding: '12px' }}>
          <Grid container columnSpacing={2} sx={{ width: '100%' }}>
            <Grid item xs={4}>
              <EnhancedTable
                tableText={classes.tableText}
                order={'asc'}
                orderBy={MULTISENSOR}
                rowsPerPageOptions={[5, 10, 15]}
                rows={generateRows(filterTable(), userDetails.user.tz)}
                headCells={tableColumns}
                isLoading={isLoadingSensors}
                isSelectable={true}
                hasRightClick={true}
                pageURL={location.pathname + location.search}
                startDate={startDate}
                endDate={endDate}
                tableRowValues={selectedTableRow}
                onClick={handleRowOnClick}
              />
            </Grid>
            <Grid container item direction={'column'} xs={4} spacing={2}>
              <Grid item xs={4} sx={{ width: '100%' }}>
                <Paper className={classes.paperCard}>
                  <QuicksightChart
                    title={X_AXIS_BAND_ONE_TO_SEVEN}
                    categories={xAxisBandLabels}
                    hasLegend={false}
                    chartWidth={'90%'}
                    chartHeight={'73%'}
                    chartUnit={chartUnit}
                    isLoading={
                      isLoadingSensors ||
                      isAggregatedReadingsLoading ||
                      isAggregatedVibrationsLoading
                    }
                    selectedSensorName={selectedTableRow?.name}
                    data={
                      convertedFFTXData.length > 0
                        ? convertedFFTXData
                        : FFTXData
                    }
                    dates={chartDates}
                    aggregatedBy={aggregatedBy}
                  />
                </Paper>
              </Grid>
              <Grid item xs={4} sx={{ width: '100%' }}>
                <Paper className={classes.paperCard}>
                  <QuicksightChart
                    title={Y_AXIS_BAND_ONE_TO_SEVEN}
                    categories={yAxisBandLabels}
                    hasLegend={false}
                    chartWidth={'90%'}
                    chartHeight={'73%'}
                    chartUnit={chartUnit}
                    isLoading={
                      isLoadingSensors ||
                      isAggregatedReadingsLoading ||
                      isAggregatedVibrationsLoading
                    }
                    selectedSensorName={selectedTableRow?.name}
                    data={
                      convertedFFTYData.length > 0
                        ? convertedFFTYData
                        : FFTYData
                    }
                    dates={chartDates}
                    aggregatedBy={aggregatedBy}
                  />
                </Paper>
              </Grid>
              <Grid item xs={4} sx={{ width: '100%' }}>
                <Paper className={classes.paperCard}>
                  <QuicksightChart
                    title={Z_AXIS_BAND_ONE_TO_SEVEN}
                    categories={zAxisBandLabels}
                    hasLegend={false}
                    chartWidth={'90%'}
                    chartHeight={'73%'}
                    chartUnit={chartUnit}
                    isLoading={
                      isLoadingSensors ||
                      isAggregatedReadingsLoading ||
                      isAggregatedVibrationsLoading
                    }
                    selectedSensorName={selectedTableRow?.name}
                    data={
                      convertedFFTZData.length > 0
                        ? convertedFFTYData
                        : FFTZData
                    }
                    dates={chartDates}
                    aggregatedBy={aggregatedBy}
                  />
                </Paper>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={4}
              direction={'column'}
              rowSpacing={2}
              sx={{ height: 'fit-content' }}
            >
              <Grid item xs={6} sx={{ width: '100%', height: '100%' }}>
                <Paper className={classes.paperCard}>
                  <QuicksightChart
                    title={AIR_CHIP_TEMPERATURE}
                    categories={airChipProperties.map((prop) => prop.label)}
                    hasLegend={true}
                    chartWidth={'90%'}
                    chartHeight={'73%'}
                    chartUnit={isMetric ? CELSIUS.unit : FAHRENHEIT.unit}
                    isLoading={
                      isLoadingSensors ||
                      isAggregatedReadingsLoading ||
                      isAggregatedVibrationsLoading
                    }
                    selectedSensorName={selectedTableRow?.name}
                    data={
                      convertedAirChipTemperatureData.length > 0
                        ? convertedAirChipTemperatureData
                        : airChipTemperatureData
                    }
                    dates={chartDates}
                    aggregatedBy={aggregatedBy}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6} sx={{ width: '100%' }}>
                <Paper className={classes.paperCard}>
                  <QuicksightChart
                    title={AIR_PARAMETERS}
                    categories={[selectedAtmosphericParameter]}
                    hasLegend={true}
                    chartWidth={'90%'}
                    chartHeight={'73%'}
                    chartUnit={
                      atmosphericParameterProperties.find(
                        (prop) => prop.label === selectedAtmosphericParameter
                      )?.unit
                    }
                    isLoading={
                      isLoadingSensors ||
                      isAggregatedReadingsLoading ||
                      isAggregatedVibrationsLoading
                    }
                    selectedSensorName={selectedTableRow?.name}
                    data={airParameterData}
                    dates={chartDates}
                    aggregatedBy={aggregatedBy}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  )
}

export default ConditionTrendDetails
