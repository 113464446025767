import * as React from "react";
import {useContext, useEffect} from "react";
import {Link} from 'react-router-dom';
import {SiteContext} from "../../Context";
import EnhancedTable from "../../shared/components/EnhancedTable/EnhancedTable";
import CopyLabel from "../../shared/components/CopyLabel/CopyLabel";
import { handlePermissionRedirect, PERMISSION_METHOD_GET, PERMISSION_METHOD_UPDATE } from "../../shared/Utilities";

import {useQuery} from 'react-query';
import {getCustomerPlants, getTimezones} from '../../query/queries';

import '../PageStyles/TablePage.css'
import translations from "../../translations/en.json";
import ActiveStatusIndicator from "../../shared/components/ActiveStatusIndicator/ActiveStatusIndicator";
import CreateButton from "../../shared/components/CreateButton";

const pageTitle = 'List Plants';
const acceptablePagePermission = [
  {entity: 'Plant', method: PERMISSION_METHOD_UPDATE, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const COLUMN_IDS = {
    NAME: "name",
    TIMEZONE: "timezone",
    ACTIVE_STATUS: "activeStatus",
    ACTIONS: "actions"
};

const generateRows = (plants, timezones) => {

  const timezoneMap = timezones.reduce((memo, value) => {
    memo[value.id] = value;
    return memo;
  }, {});

  return plants.map((plant) => {
    return {
        [COLUMN_IDS.NAME]: <CopyLabel value={plant.id} rawval={plant.name.toLowerCase()}>{plant.name}</CopyLabel>,
        [COLUMN_IDS.TIMEZONE]: timezoneMap[plant.timezone]?.formattedName || "Unknown Time Zone",
        [COLUMN_IDS.ACTIVE_STATUS]:
            <ActiveStatusIndicator
                rawval={plant.inactive ? "inactive" : "active"} // To enable sorting...
                isActive={!plant.inactive}
            />,
        [COLUMN_IDS.ACTIONS]:<Link to={"/edit-plant?plantId=" + plant.id}>Details</Link>
    }
  });
}

const columns = [
  {id: COLUMN_IDS.NAME, label: translations.pages.listPlants.name, width: "475px"},
  {id: COLUMN_IDS.TIMEZONE, label: translations.pages.listPlants.timezone, width: "525px"},
  {id: COLUMN_IDS.ACTIVE_STATUS, label: translations.pages.listPlants.status},
  {id: COLUMN_IDS.ACTIONS, label: translations.pages.listPlants.actions, width: "130px"}
];

const ListAllPlants = (props) => {

    const {setBreadcrumbs, currentCustomer, hasPermission} = useContext(SiteContext); 
    const {
        isLoading: isLoadingPlants,
        data: plants = []
    } = useQuery(['plants', {customerId: currentCustomer}], getCustomerPlants);

    const {
        isLoading: isLoadingTimezones,
        data: timezones = []
    } = useQuery(['timezones'], getTimezones);

    useEffect(() => {
        document.title = pageTitle;
        setBreadcrumbs([
            {
                title: pageTitle,
            }
        ]);
       
    }, [setBreadcrumbs]);

    const onCreateButtonClick = (e) => {
        props.history.push("/create-plant");
    };
    
    return (
        <div className="page" data-testid="list-plants-page">
          {handlePermissionRedirect(pageTitle, props.history, hasPermission, acceptablePagePermission) &&
            <div className="container">
              {hasPermission('Plant', 'insert') && <div className="createButton">
                <CreateButton onClick={onCreateButtonClick}
                                isLoading={isLoadingPlants || isLoadingTimezones}
                                label={"Create Plant"}/>
              </div>}
              <EnhancedTable
                  title="Plants"
                  order={'asc'}
                  orderBy={'Name'}
                  rows={generateRows(plants, timezones)}
                  headCells={columns}
                  isLoading={isLoadingPlants || isLoadingTimezones}
                  enableSearch
                  searchColumns={['name']}/>
            </div>
        }
      </div>
    );
};

export default ListAllPlants;
