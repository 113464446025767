import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Toolbar,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
} from '@mui/material'
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search'
import * as GreyScale from '@Theme/GreyScale.js'
import translations from '../../../translations/en.json';
import ProgressButton, { PROGRESS_BUTTON_VARIANTS } from '../ProgressButton/ProgressButton';
import FilterDrawer from '../FilterDrawer/FilterDrawer';
import moment from 'moment'
import { SiteContext } from '../../../Context';
import { filterFormats, SST_PAGE_LIST_RUNS } from '../../../Constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'unset', // Override default minHeight of > 0, for styling purposes...
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',

    '& > :not(:last-child)': {
      marginBottom: theme.spacing(1),
    }
  },
  highlight: {},
  title: {
    flex: '1 1 100%',
    lineHeight: '48px'
  },
  searchTextField: {
    width: 350,
    backgroundColor: GreyScale.GREY_1,

    '& > .MuiInput-root input': {
      paddingRight: theme.spacing(1), // When there is input text, push the clear / 'x' icon away from the right side, for looks...
    }
  },
  searchIcon: {
    margin: `0 ${theme.spacing(1)}`
  },
  actionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterButtonContainer: {
    marginLeft: 'auto'
  },
  filterContainer: {
    width: '100%',
  },
  filterList: {
    display: 'flex',
    gap: '12px',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.primary.light,
    padding: '10px'
  },
  filterPill: {
    backgroundColor: 'white'
  }
}));


const EnhancedTableToolbar = (props) => {
  const {filterAccessKey} = props;

  const classes = useToolbarStyles();
  const history = useHistory();
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const {complexFilters, setComplexFilters} = useContext(SiteContext);

  const handleFilterUpdate = (event) => {
    if(props.onFilterChange){
      props.onFilterChange(event.target.value);
    }   
  }

  const handleFilterOpen = () => {
    setIsFilterOpen(true);
  }

  const areFiltersApplied = () => {
    return complexFilters[filterAccessKey].some((filter) => {
      if(filter.type === filterFormats.DATE) {
        return !!filter.filterBy.start || !!filter.filterBy.end;
      }
      if(filter.filterBy.length > 0) {
        return true;
      }
    });
  }

  const areFiltersValid = () => {
    return complexFilters[filterAccessKey].some((filterCategory) => {
      if(filterCategory.type === filterFormats.DATE) {
        return moment(filterCategory.filterBy.start).isValid() || moment(filterCategory.filterBy.end).isValid()
      }
      return filterCategory.filterBy.some((filter) => !(filter.hasOwnProperty("invalid")))
    });
  }

  const handleFilterDeleteOnPill = (filterCategory, filterToDelete) => {
    let newVal = complexFilters[filterAccessKey].map(fOption => {
      if(fOption.type === filterFormats.DATE && filterCategory.type === filterFormats.DATE) {
        filterToDelete.forEach(prop => {
          if(fOption.filterBy.hasOwnProperty(prop)) {
            fOption.filterBy[prop] = null;
          }
        })
        return fOption;
      }
      if(fOption.id === filterCategory.id) {
        return ({
          ...fOption,
          filterBy: fOption.filterBy.filter(item => !filterToDelete.some(key => key in item))
        })
      }
      return fOption
    });
    setComplexFilters((tables) => ({
      ...tables,
      [filterAccessKey]: newVal
    }));
    // The replace here is to do with removing the filter in the params as opposed to re-rendering the page
    history.replace(`/${SST_PAGE_LIST_RUNS}`);
  }

  const getFilterPillItem = (filter, appliedFilter, index) => {
    let itemForPill = [];
    let label = "";

    // Do not show pill for invalid filters
    if(appliedFilter.hasOwnProperty("invalid")) {
      return;
    }
  
    if (filter.type === filterFormats.DATE) {
      let startDate = filter.filterBy.start;
      let endDate = filter.filterBy.end;
      let [startKey, endKey] = Object.keys(filter.filterBy);
      
      // Handle date filters
      if(startDate !== null && endDate !== null) {
        if(startDate === endDate) { // If both dates are the same, show only one date
          label = moment(startDate).format("lll"); 
          itemForPill = [startKey];
        } else {
          label = "Between " + moment(startDate).format("lll") + " and " + moment(endDate).format("lll") // If there is a start and end date, but they're different
          itemForPill = [startKey, endKey];
        }

      } else if(startDate !== null){
        label = moment(startDate).format("lll"); // If there is a start date
        itemForPill = [startKey];
      } else if(endDate !== null){
        label = moment(endDate).format("lll"); // If there is a end date
        itemForPill = [endKey];
      }
    } else {
      // Handle regular filters
      let [key] = Object.entries(appliedFilter)[0];
      if (key) {
        label = key;
        itemForPill = [key];
      }
    }

    return (
      <Chip
        key={index}
        className={classes.filterPill}
        label={label}
        variant="outlined"
        onDelete={() => handleFilterDeleteOnPill(filter, itemForPill)}
      />
    );
  }

  return (
    <Toolbar
      className={props.rootStyle ? props.rootStyle : classes.root}
      data-testid="EnhancedTableToolbar">

      {/* Title */}
      { props.title &&
        <Typography
          className={props.titleStyle ? props.titleStyle : classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
          data-testid="EnhancedTableTitle">
            {props.title}
        </Typography>
      }

      {/* Search Field */}
        <div className={classes.actionContainer}>
          {props.enableSearch &&
            <TextField
              className={classes.searchTextField}
              id="tableSearch"
              placeholder={translations.components.enhancedTable.search}
              type="search"
              inputProps={{'data-testid':"EnhancedTableFilter"}}
              InputProps={{
                startAdornment: (
                  <SearchIcon className={classes.searchIcon}/>
                ),
              }}
              onChange={handleFilterUpdate}
            />
          }
          {(props.enableFilter && Object.hasOwn(complexFilters, filterAccessKey)) && 
            <div className={classes.filterButtonContainer}>
              <ProgressButton variant={PROGRESS_BUTTON_VARIANTS.ACTION} text="Filter" onClick={() => handleFilterOpen()} isLoading={props.isLoading} />
              <FilterDrawer open={isFilterOpen} setOpen={setIsFilterOpen} filterAccessKey={filterAccessKey} />
            </div>
          
          }
        </div>
        {(props.enableFilter && Object.hasOwn(complexFilters, filterAccessKey)) && 
          <div className={classes.filterContainer}>
            <Accordion expanded={areFiltersApplied()}>
              <AccordionSummary style={{display: 'none'}} />
              <AccordionDetails className={classes.filterList}>
                {areFiltersValid() ?
                  complexFilters[filterAccessKey].map((filter, index) => {
                    if(filter.type === filterFormats.DATE && (filter.filterBy.start || filter.filterBy.end)) { // Since the start and end date filters are both used in the same pill, only run once
                      return getFilterPillItem(filter, null, index)
                    } else if(filter.type !== filterFormats.DATE) {
                      return filter.filterBy.map((appliedFilter, index) => {
                          return getFilterPillItem(filter, appliedFilter, index)
                      })
                    }
                    return null;
                  })
                :
                  areFiltersApplied() ? <Typography sx={{fontSize: 12}}>{translations.components.enhancedTable.invalidFilters}</Typography> : undefined
                }
              </AccordionDetails>
            </Accordion>
          </div>
        }
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  onFilterChange: PropTypes.func
};

export default EnhancedTableToolbar;