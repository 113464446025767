import React, {useContext, useEffect, useState} from "react";
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useHistory, useLocation} from 'react-router-dom';

import CreateEditWrapper from "../../shared/components/CreateEdit/CreateEditWrapper";
import {useSnackbar} from 'notistack';
import {MenuItem, TextField, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import queryString from "query-string";
import {SiteContext} from "../../Context";
import SSTDropdown from "../../shared/components/SSTDropdown";
import translations from '../../translations/en.json';
import { handlePermissionRedirect, PERMISSION_METHOD_GET, PERMISSION_METHOD_INSERT } from "../../shared/Utilities";

import {
    createLine,
    deleteLine,
    getCustomer,
    getCustomerPlants,
    getEntityPermissions,
    getLine,
    updateLine
} from '../../query/queries';
import QuantifeelSwitch from "../../shared/components/QuantifeelSwitch";
import CreateButton from "../../shared/components/CreateButton";

const acceptablePagePermission = [
  {entity: 'Line', method: PERMISSION_METHOD_INSERT, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const emptyLine = {
  name: "",
  plantId: ""
};

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  content: {
    width: '500px',
    margin: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '30px'
  },
  formControl: {
    fontSize: '20px'
  },
  noPlantsErrorMessage: {
      fontSize: '11px',
      color: 'red'
  },
  plantsContainer: {
    width: '100%',
    display: 'flex',
    gap: '20px'
  },
  plantButton: {
    margin: '8px -255px 0 0'
  }
})


const CreateAndEditLine = (props) => {

    const {setBreadcrumbs, currentCustomer, hasPermission} = useContext(SiteContext);
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const location = useLocation();
    const queryClient = useQueryClient();

    const [isEdit, setIsEdit] = useState(false);

    const [line, setLine] = useState(emptyLine);

    const {isLoading: isLoadingLine, data: lineData} = useQuery(['line', {lineId: line.id}], getLine, {enabled: !!line.id});

    const {isLoading: isLoadingCustomer, data:customer={}} = useQuery(['customer', {customerId: currentCustomer}], getCustomer);
    const {isLoading: isLoadingPlants, data:plants=[]} = useQuery(['plants', {customerId:currentCustomer}], getCustomerPlants);

    const {isLoading: isLoadingPermissions, data: permissions} = useQuery(['permissions', currentCustomer, 'line', {ids: [line.id]}], getEntityPermissions, {
      enabled: !!line.id
    });

    const readOnly = isEdit ? !permissions?.line[line.id]?.update : false;
    const pageTitle = location.pathname === '/create-line' ? 'Create Line' : 'Edit Line';
    const isPlantsEmpty = plants.length === 0;

    const {mutate: mutateUpdate, isLoading: isUpdating} = useMutation(updateLine, {
      onSuccess: (data) => {
        enqueueSnackbar('Line Updated', {variant: 'success'});
        queryClient.removeQueries('lines');
        queryClient.setQueryData(['line', {lineId: data.id}], data);
      },
      onError: ({response: {data}}) => {
        enqueueSnackbar(data.message, {variant: 'error'});
      }
    });

    const {mutate: mutateCreate, isLoading: isCreating} = useMutation(createLine, {
      onSuccess: (data) => {
        enqueueSnackbar('Line Created', {variant: 'success'})
        queryClient.removeQueries('lines');
        queryClient.setQueryData(['line', {lineId: data.id}], data);
        history.replace(`/list-lines`);
      },
      onError: ({response: {data}}) => {
        enqueueSnackbar(data.message, {variant: 'error'});
      }
    });

    const {mutate: mutateDelete, isLoading: isDeleting} = useMutation(deleteLine, {
      onSuccess: (data) => {
        enqueueSnackbar('Line Deleted', {variant: 'success'})
        queryClient.removeQueries('lines');
        queryClient.setQueryData(['line', {lineId: data.id}], undefined);
        history.replace('/list-lines');
      },
      onError: ({response: {data}}) => {
        enqueueSnackbar(data.message, {variant: 'error'});
      }
    });

    useEffect(() => {
      if(lineData){
        setLine(lineData);
      }
    }, [lineData]);

    useEffect(() => {
        
        const qs = queryString.parse(location.search);

        if (qs.lineId) {
            document.title = "Edit Line";
            setIsEdit(true);
            setLine(l => {return {...l, id: qs.lineId}});
            setBreadcrumbs([{title: "Edit Line"}]);
        } else {
            document.title = "Create Line";
            setIsEdit(false);
            const lineCopy = JSON.parse(JSON.stringify(line));

            if(qs.plantId) {
              lineCopy.plantId = qs.plantId;
            }
            if(qs.lineName) {
              lineCopy.name = qs.lineName;
            }

            setLine(lineCopy);
            setBreadcrumbs([{title: "Create Line"}]);
        }
        
    }, [setBreadcrumbs, location.search]);

    const attemptCreateEditLine = () => {
      if(isEdit){
        mutateUpdate(line);
      }
      else{
        mutateCreate(line);
      }        
    };

    const attemptDeleteLine = (e) => {
      e.preventDefault();
      if (window.confirm(`Are you sure you want to delete the line "${line.name}"`)) {
        mutateDelete(line);
      }
    }

    const handleFormUpdate = (update) => {
      setLine({...line, ...update});
    }

    const submit = (e) => {
        e.preventDefault();
        attemptCreateEditLine();
    };

    const handleCreatePlant = () => {
        if(line.name !== "") {
            history.push(`/create-plant?lineName=${line.name}`, {from: location.pathname});
        } else {
            history.push("/create-plant", {from: location.pathname});
        }
    }

    const onDelete = isEdit && attemptDeleteLine;

    const classes = useStyles();

    return (
        <div data-testid={isEdit ? 'edit-line-page' : 'create-line-page'} className={classes.root}>
          {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
            <CreateEditWrapper
              onBack={() => !!(history.location.key) ? history.goBack() : history.push('/list-lines')}
              buttonTitle={isEdit ? "Save" : "Create"}
              pageTitle={isEdit ? "Edit Line" : "Create Line"}
              submit={submit}
              isDeleting={isDeleting}
              disableSubmit={readOnly}
              isLoading={isUpdating || isCreating || isLoadingLine || isLoadingCustomer || isLoadingPlants || isLoadingPermissions}
              classes={classes}
              headerContentRight={
                  <QuantifeelSwitch
                      checked={!line.inactive}
                      onChange={(e) => {handleFormUpdate({inactive: !e.target.checked})}}
                  />
              }
            >
                <div className={classes.content}>
                    <SSTDropdown 
                      classes={classes}
                      disabled={true}
                      isLoadingContents={isLoadingCustomer}
                      label={"Customers"}
                      selectedValueId={currentCustomer}
                      setValueFunc={() => {}}
                      mappedList={[customer].map((c) => <MenuItem 
                                                                key={c.name}
                                                                value={c.id}>{c.name}</MenuItem>)}/>

                    <div>
                        <div className={classes.plantsContainer}>
                            <SSTDropdown classes={classes}
                                         disabled={isLoadingPlants || isEdit}
                                         readOnly={readOnly}
                                         isLoadingContents={isLoadingPlants}
                                         label={"Plants"}
                                         selectedValueId={line.plantId}
                                         setValueFunc={(e) => {handleFormUpdate({plantId: e.target.value})}}
                                         mappedList={plants.map((plant) => <MenuItem
                                             key={plant.name}
                                             value={plant.id}>{plant.name}</MenuItem>)}/>
                            <div className={classes.plantButton}>
                                {isPlantsEmpty &&
                                    <CreateButton
                                        isLoading={isLoadingPlants}
                                        label={translations.pages.createAndEditLine.createPlant}
                                        onClick={() => handleCreatePlant()}
                                        data-testid="CreateEditSubmit"
                                    />
                                }
                            </div>
                        </div>
                        {isPlantsEmpty &&
                            <Typography className={classes.noPlantsErrorMessage}>{translations.pages.createAndEditLine.noPlantsErrorMsg}</Typography>
                        }
                    </div>
                    <TextField
                      value={line.name}
                      onChange={(e) => {handleFormUpdate({name: e.target.value})}}
                      className={classes.textField}
                      required
                      id="lineName"
                      name="lineName"
                      label="Line Name"
                      fullWidth
                      InputProps={{
                        readOnly: readOnly
                      }}
                      disabled={isUpdating || isCreating || isLoadingLine}/>
                </div>
                
            </CreateEditWrapper>
          }
        </div>);
};

export default CreateAndEditLine;
