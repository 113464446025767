import {createTheme} from '@mui/material/styles';
import WorkSansTTF from '../../font/WorkSans-Regular.ttf';

import tinyColor from "tinycolor2";
import * as GreyScale from "./GreyScale";

export const primary = "#2F2F2F"; // Dark grey from branding guide
export const secondary = "#FF5C93";
export const danger = "#ff4444";
export const warning = "#FF8800"; //#ffbb33
export const success = "#00695c";
export const info = "#aa66cc";
export const mainRed = '#D02C2F';

const darkenRate = 15;

// Work around for preview prs
const WorkSansTTFURL = (process.env.REACT_APP_PREVIEW === "true") ? window.location.href.replace(/\/(index\.html.*)?$/, "").concat(WorkSansTTF) : WorkSansTTF;

export default createTheme({
    typography: {
      fontFamily: 'WorkSans'
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": {
            fontFamily: 'WorkSans',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `url(${WorkSansTTFURL})`,
            unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
          },
          '@global': {
            '*::-webkit-scrollbar-track': {
              backgroundColor: 'rgba(0,0,0,0.03)'
            },
            '*::-webkit-scrollbar-corner': {
              backgroundColor: 'rgba(0,0,0,0.03)'
            },
            '*::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              borderRadius: '30px',
            }
          },
        }
        
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard'
        }
      },
      MuiTab: {
        styleOverrides: {
            root: {
                textTransform: 'none',
                backgroundColor: GreyScale.GREY_2,
                color: GreyScale.GREY_5,
                fontWeight: "bold",
                fontSize: 16,
                '&.Mui-selected': {
                    borderBottom: `2px solid ${mainRed}`,
                    backgroundColor: 'white',
                },
            },

        },
      },
        MuiTableHead: {
          styleOverrides: {
              root: {
                  backgroundColor: primary,
              }
          }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: GreyScale.GREY_2, // Selected row color
                        '&.MuiTableRow-hover:hover': {
                            backgroundColor: GreyScale.GREY_2, // Color of a selected item on hover
                        },
                    },
                    '&.MuiTableRow-hover:hover': {
                        backgroundColor: GreyScale.GREY_1,
                    },
                }
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: "white"
                }
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: "#efefef",
                        '&:hover': {
                            backgroundColor: GreyScale.GREY_1, // Color of a selected item on hover
                        },
                    },
                    "&:hover": {
                        backgroundColor: GreyScale.GREY_1// Color on hover of a menu item
                    }
                }
            },
        }
    },
    palette: {
        // This is used for the grey color you see throughout the app.
        primary: {
            main: primary,
            light: tinyColor(primary)
                .lighten(65)
                .toHexString(),
            dark: tinyColor(primary)
                .darken(darkenRate)
                .toHexString()
        },
        text: {
            primary: "#4A4A4A",
            secondary: "#6E6E6E",
            hint: "#B9B9B9"
        },
        // This action section is used mostly for action elements (buttons, switches, radio buttons, etc.)
        action: {
            main: '#0E4DA4',
            hover: '#09336B'
        },
        // This is used for the accent of the color red that is used throughout the app
        accent: {
            main: mainRed,
            hover: '#9F1B1B'
        },

        hyperlinkBlue: '#007bff',

        // Grey
        darkGrey: '#8F8F8F',

        // Orange
        orange: '#FF7A00',
        spinOrange: '#fba91b',

        // Yellow
        rotationYellow: '#F5D218',
    },
    customShadows: {
        widget:
            "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
        widgetDark:
            "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
        widgetWide:
            "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A"
    },
});