import { makeStyles } from '@mui/styles'
import * as GreyScale from "../../../shared/Theme/GreyScale";

const useStyles = makeStyles(theme => ({

  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  headerButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  },
  machineCenterDetailsStyle: {
    height: 48,
    backgroundColor: GreyScale.GREY_1,
    border: `1px solid ${GreyScale.GREY_2}`,
    display: 'flex',
    padding: '0 26px',
    alignItems: 'center'
  },
  machineCenterHeadingStyle: {
    flex: 1
  },
  machineCenterDetailsHeader: {
    fontWeight: 'bold',
  },
  machineCenterDetailsActionsContainer: {
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(3)
    }
  },
  tablesContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '26px',

    borderTop: `1px solid black`,

    '& > :not(:last-child)': {
      marginBottom: '52px'
    }
  },
  tableContainer: {
    '& > :not(:last-child)': {
      marginBottom: '26px'
    }
  },
  tableContainerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableHeaderButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  textOverflowHiddenEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }
}));
export default useStyles