import { makeStyles } from '@mui/styles'
import * as GreyScale from '../../../shared/Theme/GreyScale'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  controlPointsListContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 26,
    gap: theme.spacing(2),
  },
  controlPointsListRow: {
    display: 'flex',
    height: 32,
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center'
  },
  controlPointsListRowInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    borderBottom: `1px solid ${GreyScale.GREY_2}`,
  },
  controlPointIndexLabel: {
    width: 35,
    display: 'flex',
    justifyContent: 'right',
  },
  controlPointNameLabel: {
    opacity: .5,
    width: 350,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  footerContentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerContentContainerSubContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(5),
    }
  },
  moveIcon: {
    cursor: 'pointer',
  }
}))

export default useStyles