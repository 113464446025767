import { CONTROL_POINT_SECTION_STATES } from '../Constants'

export const getUnifiedControlChartStatus = ({controlLimitStatus, specificationLimitStatus}) => {

  // Both IN_CONTROL...
  if ( controlLimitStatus === CONTROL_POINT_SECTION_STATES.IN_CONTROL.key &&
       specificationLimitStatus === CONTROL_POINT_SECTION_STATES.IN_CONTROL.key) {
    return CONTROL_POINT_SECTION_STATES.IN_CONTROL.key
  }

  // At least one is OUT_OF_CONTROL...
  if ( controlLimitStatus === CONTROL_POINT_SECTION_STATES.OUT_OF_CONTROL.key ||
       specificationLimitStatus === CONTROL_POINT_SECTION_STATES.OUT_OF_CONTROL.key) {
    return CONTROL_POINT_SECTION_STATES.OUT_OF_CONTROL.key
  }

  // At least one is INSUFFICIENT_DATA...
  if ( controlLimitStatus === CONTROL_POINT_SECTION_STATES.INSUFFICIENT_DATA.key ||
       specificationLimitStatus === CONTROL_POINT_SECTION_STATES.INSUFFICIENT_DATA.key) {
    return CONTROL_POINT_SECTION_STATES.INSUFFICIENT_DATA.key
  }

  // At least one is DIDNT_SEE_CONTROL_POINT...
  if ( controlLimitStatus === CONTROL_POINT_SECTION_STATES.DIDNT_SEE_CONTROL_POINT.key ||
       specificationLimitStatus === CONTROL_POINT_SECTION_STATES.DIDNT_SEE_CONTROL_POINT.key) {
    return CONTROL_POINT_SECTION_STATES.DIDNT_SEE_CONTROL_POINT.key
  }

  return null;
}