import {
  CircularProgress,
  Fade,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import Styles from './Styles'
import { SiteContext } from '../../../Context'

/**
 * https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/tables/EnhancedTable.js
 * **/

function descendingComparator(a, b, orderBy) {
  const valA = a[orderBy]?.props?.rawval || a[orderBy]
  const valB = b[orderBy]?.props?.rawval || b[orderBy]

  if (valB < valA) {
    return -1
  }
  if (valB > valA) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}

const filterRows = (rows, filterString, columns) => {
  return rows.filter((row) => {
    return columns.reduce((memo, column) => {
      if (memo === true) {
        return true
      }
      const val = row[column]
      if (typeof val === 'string') {
        return val.toLowerCase().indexOf(filterString.toLowerCase()) >= 0
      } else if (typeof val === 'object' && val?.props?.rawval) {
        return (
          val.props?.rawval
            ?.toLowerCase()
            .indexOf(filterString.toLowerCase()) >= 0
        )
      }
      return false
    }, false)
  })
}

export default function EnhancedTable(props) {
  let {
    rows,
    headCells,
    setPaginationValues,
    filterOptions,
    filterAccessKey,
    searchColumns,
    tableText,
    hasRightClick,
    hasColumnTitles = true,
    pageURL,
    isSelectable = false,
    squareCorners = false,
    blackBorder = false,
    pagination = true,
    resetPagination = false,
    onResetPaginationComplete,
    hasBoldNumbers = false
  } = props

  const location = useLocation()
  const history = useHistory()
  const {complexFilters, setComplexFilters} = useContext(SiteContext);

  const [order, setOrder] = useState(props.order.toString())
  const [orderBy, setOrderBy] = useState(props.orderBy.toString())
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(
    props.rowsPerPage ? props.rowsPerPage : 10
  )
  const [selectedRow, setSelectedRow] = useState({})
  const [filter, setFilter] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [rightClickedSensorId, setRightClickedSensorId] = useState('')

  const classes = Styles()

  const titleStyle = props.styleTable ? props.styleTable[1] : undefined
  const rowDataStyle = props.styleTable
    ? props.styleTable[0]
    : classes.tableData
  const toolbarRootStyle = props.styleTable ? props.styleTable[2] : undefined

  const filteredRows =
    props.enableSearch && props.searchColumns && props.searchColumns.length > 0
      ? filterRows(rows, filter, searchColumns)
      : rows

  const sortedFilteredRows = stableSort(
    filteredRows,
    getComparator(order, orderBy)
  )

  const paginatedSortedFilteredRows = sortedFilteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // When using pagination with an API call on each change of the page, the page will always be set to zero as we use server-side filtering which returns enough rows for one page (ie. page index zero)
  const firstPageSortedFilteredRows = sortedFilteredRows.slice(0, rowsPerPage);

  const rowsToRender = pagination ? (!!(props.enableFilter) ? firstPageSortedFilteredRows : paginatedSortedFilteredRows) : sortedFilteredRows;

  // Calculate total number of items
  const totalItemCount = props.totalNumberOfItems || filteredRows.length;

  // Calculate the page index to use
  const currentPageIndex = props.totalNumberOfItems ? page : (props.enableFilter ? 0 : page);
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    const orderDirection = isAsc ? 'desc' : 'asc';

    if(!!(setPaginationValues)) {
      setPaginationValues((pagination) => 
        ({...pagination, 
          sortDirection: orderDirection.toUpperCase(),
          sortBy: property
        })
      )
    }

    setOrder(orderDirection)
    setOrderBy(property)
  }

  useEffect(() => {
    let prevSelectedRow = sortedFilteredRows.find(
      (row) => selectedRow?.id === row?.id
    )
    if (!!prevSelectedRow) {
      setSelectedRow({
        row: prevSelectedRow.row || {},
        id: prevSelectedRow.id || null,
        page: prevSelectedRow.page || -1,
      })
    } else {
      setSelectedRow({})
    }
    //eslint-disable-next-line
  }, [order, orderBy])

  useEffect(() => {

    if (resetPagination) {

      // Reset...
      setPage(0);

      // Callback...
      onResetPaginationComplete();
    }
  }, [resetPagination]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(!props.isLoading && !!(setPaginationValues)) {
     if ((page + 1) * rowsPerPage >= rows.length ) {
        setPaginationValues((pagination) => ({...pagination, page: page}))
      }
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(Object.hasOwn(complexFilters, filterAccessKey) && complexFilters[filterAccessKey]?.length > 0) {
      setPage(0)
    }
  }, [complexFilters, filterAccessKey])

  useEffect(() => {
    if(!!(filterOptions)) {
      if(props.resetFilters && filterOptions.length > 0) {
        setComplexFilters((tables) => ({
          ...tables,
          [filterAccessKey]: filterOptions
        }));
      }
    }
  }, [filterOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (event, row, index, page) => {
    if (index > -1) {
      setSelectedRow({
        page: page,
        row: index,
        id: row?.id,
      })
    }
    if (props.onClick) {
      if (!!location.state?.previouslySelectedRow) {
        location.state.previouslySelectedRow = undefined
      }
      props.onClick(event, row.id, row)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)

    if(!!(setPaginationValues)) {
      setPaginationValues((pagination) => ({...pagination, pageSize: parseInt(event.target.value, 10)}))
    }
  }

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter)
    setPage(0)
  }

  const handlePopper = (e, row) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
    setIsPopoverOpen((prev) => row.id !== rightClickedSensorId || !prev)
    setInterval(() => {
      setIsPopoverOpen(false)
      clearInterval()
    }, 5000)
    setRightClickedSensorId(row.id)
  }

  const isRowSelected = (row) => {
    if (isSelectable) {
      if (
        !!location.state?.previouslySelectedRow &&
        row.id === location.state.previouslySelectedRow
      ) {
        return row.id === location.state.previouslySelectedRow
      } else if (!location?.state?.previouslySelectedRow) {
        return row.id === selectedRow.id
      }
    }
    return false
  }

  const renderPagination = () => {
    return (
      <TablePagination
        rowsPerPageOptions={props.rowsPerPageOptions || [5, 10, 25]}
        component="div"
        count={totalItemCount}
        rowsPerPage={rowsPerPage}
        page={currentPageIndex}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        classes={{
          selectLabel: classes.selectLabel,
          displayedRows: classes.selectLabel,
        }}
      />
    )
  }

  return (
    <div className={classes.root}>
      <Paper
        className={classes.paper}
        variant={ blackBorder ? 'outlined' : undefined }
        style={ blackBorder ? {border: '1px solid black'} : undefined }
        square={squareCorners}
      >
        {(props.title || props.enableSearch || props.enableFilter) && (
          <EnhancedTableToolbar
            title={props.title}
            titleStyle={titleStyle}
            rootStyle={toolbarRootStyle}
            enableFilter={props.enableFilter}
            filterAccessKey={filterAccessKey}
            enableSearch={props.enableSearch}
            onFilterChange={handleFilterChange}
            isLoading={props.isLoading}
          />
        )}
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            padding={'normal'}
            size={'medium'}
            data-testid="EnhancedTable"
          >
            {hasColumnTitles && (
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                enableServerSideSorting={props.enableServerSideSorting}
                headCells={headCells}
              />
            )}

            {props.isLoading ? (
              <tbody>
                <tr>
                  <td align="center" colSpan={headCells.length}>
                    <CircularProgress
                      className={classes.progressIndicator}
                      variant="indeterminate"
                      disableShrink={false}
                      color={'inherit'}
                      size={23}
                    />
                  </td>
                </tr>
              </tbody>
            ) : typeof rows === 'undefined' || rows.length === 0 ? (
              <tbody>
                <tr>
                  <td align="center" colSpan={headCells.length}>
                    <Typography component={'div'} className={classes.noData}>
                      <b>Sorry, No Data</b>
                    </Typography>
                  </td>
                </tr>
              </tbody>
            ) : (
              <TableBody>
                {rowsToRender
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover={isSelectable}
                        onClick={(event) =>
                          handleClick(event, row, index, page)
                        }
                        onContextMenu={(event) => handlePopper(event, row)}
                        tabIndex={-1}
                        key={index}
                        selected={isRowSelected(row, index)}
                        data-testid="EnhancedTableRow"
                      >
                        {Object.keys(row).map((key, index1) => {
                          if (headCells[index1].hidden) {
                            return undefined
                          }
                          return (
                            <TableCell
                              component="th"
                              id={index1}
                              key={key + index1}
                              scope="row"
                            >
                              <Typography
                                component={'div'}
                                className={
                                  !!tableText ? tableText : rowDataStyle
                                }
                                minWidth={headCells[index1]?.width}
                                maxWidth={headCells[index1]?.width}
                                sx={{fontWeight: (typeof row[key] === 'number') && hasBoldNumbers ? 'bold' : 'normal'}}
                              >
                                {row[key]}
                              </Typography>
                            </TableCell>
                          )
                        })}
                        {!!hasRightClick && (
                          <Popper
                            id="row-rightclick-popper"
                            open={isPopoverOpen}
                            anchorEl={anchorEl}
                            transition
                            placement={'bottom-start'}
                            className={classes.popover}
                          >
                            {({ TransitionProps }) => (
                              <Fade {...TransitionProps} timeout={400}>
                                <div
                                  className={classes.waveformPopup}
                                  onClick={() => {
                                    history.push(
                                      `/sensors/${rightClickedSensorId}/combined-vibration`,
                                      JSON.stringify({
                                        previouslySelectedRow:
                                          rightClickedSensorId,
                                        goBackURL: pageURL,
                                        startDate: props.startDate,
                                        endDate: props.endDate,
                                      })
                                    )
                                  }}
                                >
                                  <Typography
                                    className={classes.rightClickLinkStyle}
                                  >
                                    Time Waveform and FFT
                                  </Typography>
                                </div>
                              </Fade>
                            )}
                          </Popper>
                        )}
                      </TableRow>
                    )
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        { pagination && renderPagination() }
      </Paper>
    </div>
  )
}

EnhancedTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number,
  isSelectable: PropTypes.bool,
  enableFilter: PropTypes.bool,
  enableSearch: PropTypes.bool,
  searchColumns: PropTypes.arrayOf(
    (propValue, key, componenetName, location, propFullName) => {
      if (propValue.length === 0) {
        return new Error(
          `Invalid Prop ${propFullName} supplied to ${componenetName}. ${propFullName} must not be an empty array`
        )
      }
    }
  ),
}
